import React, { useState, useEffect, useRef } from "react";
import "./bet.css";
import imgPrint from "../../assets/venta/imprimir.png";
import imgLogo from "../../assets/logo.png";
import imgDel from "../../assets/venta/ELIMINAR.png";
import imgRepeat from "../../assets/venta/btnrepetir.png";
import btnDirect from "../../assets/venta/btndirecto.png";
import exacta from "../../assets/venta/exacta1.png";
import colocada from "../../assets/venta/colocada1.png";
import all from "../../assets/venta/contodos.png";
import imgEnter from "../../assets/venta/enter2.png";
import btnAhead from "../../assets/venta/btnpalante.png";
import { CalcularApostado } from "../../utils/Calculo-apostado";
import { headers, heightCanvas, url, widthCanvas } from "../../utils/const";
import Dashboad from "../dashboard/Dashboard";
import { Logout } from "../../utils/logout";
import { DoorOpen, DoorOpenFill } from "react-bootstrap-icons";
import Barcode from "react-jsbarcode";
import { socket } from "../../io";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { addSeconds } from "date-fns";
import { Stage, Layer, Rect, Circle, Text, Group, Image as KonvaImage } from "react-konva";
let betting = [];
const wt = 50;
let bettingShow = [];
let print = undefined;

// Función para cargar una imagen
const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new window.Image();
    img.src = src;
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
  });
};

function Bet() {
  const [sizeRef, setSizeRef] = useState(1024);
  const [firstBet, setFirstBest] = useState("");
  const [secondBet, setSecondBet] = useState("");
  const [thirtBet, setThirdBet] = useState("");
  const [time, setTime] = useState("");
  const [hour, setHour] = useState("");
  const [nextRun, setNextRun] = useState("");
  const [run, setRun] = useState("");
  const [betted, setBetted] = useState(0);
  const [selected, setSelected] = useState({
    p11: "",
    p12: "",
    p13: "",
    p14: "",
    p15: "",
    p16: "",
    p21: "",
    p22: "",
    p23: "",
    p24: "",
    p25: "",
    p26: "",
    p31: "",
    p32: "",
    p33: "",
    p34: "",
    p35: "",
    p36: "",
  });
  const [total, setTotal] = useState(0);
  const [inTeclado, setInTeclado] = useState("");
  const [winner, setWinner] = useState("");
  const [show, setShow] = useState("");
  const [active, setActive] = useState(false);
  const [newTicketList, setNewTicketList] = useState([]);
  const [showTextArea, setShowTextArea] = useState(false);
  const [PrintSt, setPrintSt] = useState();
  const [isPressing, setIsPressing] = useState(true);
  const [btnEnter, SetBtnEnter] = useState();
  const [btnRepetir, setBtnRepetir] = useState();
  const [btnOption, setBtnOption] = useState([]);
  const [pressStartTime, setPressStartTime] = useState(null);
  const [pressDuration, setPressDuration] = useState(0);
  const [isTouching, setIsTouching] = useState(true);
  const [lastTouch, setLastTouch] = useState();
  const [showTicket, setShowTicket] = useState(false);
  const timeoutRef = useRef(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [state, setState] = useState(false);

  let newTicket = [];
  let contentPrint = "print";
  const imgList = [
    { img: btnDirect, text: "DIRECTO", x: 10, color: `white`, border: `blue`, bg: `red` },
    { img: btnAhead, text: "PATRA Y PALANTE", x: 10, color: `red`, border: `blue`, bg: `` },
    { img: all, text: "CON TODOS", x: 10, color: `black`, border: `blue`, bg: `white` },
    { img: exacta, text: "EXACT", x: 10, color: `white`, border: `blue`, bg: `red` },
    { img: colocada, text: "COLOCA", x: 10, color: `white`, border: `blue`, bg: `red` },
    { img: imgDel, text: "ELIMINAR", x: 10, color: `black`, border: `blue`, bg: `orange` },
    { img: imgPrint, text: "PRINTER", x: 10, color: `yellow`, border: `blue`, bg: `green` },
  ];
  useEffect(() => {
    socket.emit("state", localStorage.getItem("point"));
    socket.on("state-res", (data) => {
      setState(data.state);
    });
  }, []);
  useEffect(() => {
    window.addEventListener("mouseup", handleMouseUp);
    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  /**Determinaa le madida mas peque;a de la pantalla para tomar su medida como referencia */
  useEffect(() => {
    const w = window.innerWidth;
    const h = window.innerHeight;
    setSizeRef(w < h ? w : h);
  }, [sizeRef]);
  /**Para cargar imagenes */
  useEffect(() => {
    const loadAllImages = async () => {
      try {
        const allImgPromises = imgList.map((option) => loadImage(option.img).then((img) => ({ ...option, img })));
        const allImg = await Promise.all(allImgPromises);
        setBtnOption(allImg);
        setImagesLoaded(true); // Marcar que todas las imágenes se han cargado
      } catch (err) {
        console.error("Error loading images:", err);
      }
    };

    loadAllImages();
  }, []);
  useEffect(() => {
    const img = new window.Image();
    img.src = imgEnter;
    img.onload = () => SetBtnEnter(img);
  }, [btnEnter]);
  useEffect(() => {
    const img = new window.Image();
    img.src = imgRepeat;
    img.onload = () =>
      setBtnRepetir({
        img: img,
        text: "REPETIR",
        x: 10,
        color: `white`,
        border: `blue`,
        bg: `blue`,
      });
  }, []);
  /** Datos de la Carrera actual para cual sera la apuesta */
  useEffect(() => {
    if (!localStorage.getItem("user")) return (window.location.href = "/");
    socket.on("screen", (data) => {
      setTime((value) => {
        if (value && value <= 10) {
          setSelected({
            p11: "",
            p12: "",
            p13: "",
            p14: "",
            p15: "",
            p16: "",
            p21: "",
            p22: "",
            p23: "",
            p24: "",
            p25: "",
            p26: "",
            p31: "",
            p32: "",
            p33: "",
            p34: "",
            p35: "",
            p36: "",
          });
          betting = [];
          setTotal(() => 0);
          setFirstBest("");
          setSecondBet("");
          setBetted(0);
          setActive(false);
        }
        return data.time;
      });

      const hr = `${new Date().getHours().toString().padStart(2, "0")}:${new Date().getMinutes().toString().padStart(2, "0")}:${new Date().getSeconds().toString().padStart(2, "0")}`;
      const lasthours = addSeconds(new Date(), data.time);
      setHour(() => hr);
      setNextRun(() => `${lasthours.getHours().toString().padStart(2, "0")}:${lasthours.getMinutes().toString().padStart(2, "0")}:${lasthours.getSeconds().toString().padStart(2, "0")}`);
    });
    if (PrintSt && PrintSt.num) {
      if (localStorage.getItem("level") !== "4") return (window.location.href = "/");
      window.print();
      setPrintSt(undefined);
      print = undefined;
    }
    /**Socket.Io */
    socket.on("run", (data) => setRun(data.run));
    /**Lectura codigo de barra */
    // Agregar el evento al documento cuando el componente se monta
    const handleKeyDown = (event) => {
      if (event.key !== "Enter") {
        const nume = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
        if (nume.includes(Number(event.key))) InputTeclado(event.key);
      } else {
        if (event.key === "Enter") setShowTicket(true);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    // Eliminar el evento al documento cuando el componente se desmonta
    return () => {
      // socket.off("screen", handleScreen);
      socket.off("run", setRun);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [PrintSt]);

  if (!localStorage.getItem("user")) {
    return (window.location.href = "/");
  }

  const addValue = (place, num) => {
    if (!lastTouch || new Date().getTime() - pressDuration > 150) {
      if (place === 1) {
        if (num === 1) {
          setSelected((selected) => {
            if (selected.p11 === "betted") {
              return { ...selected, p11: "" };
            } else {
              return { ...selected, p11: "betted" };
            }
          });
        }
        if (num === 2) {
          setSelected((selected) => {
            if (selected.p12 === "betted") {
              return { ...selected, p12: "" };
            } else {
              return { ...selected, p12: "betted" };
            }
          });
        }
        if (num === 3) {
          setSelected((selected) => {
            if (selected.p13 === "betted") {
              return { ...selected, p13: "" };
            } else {
              return { ...selected, p13: "betted" };
            }
          });
        }
        if (num === 4) {
          setSelected((selected) => {
            if (selected.p14 === "betted") {
              return { ...selected, p14: "" };
            } else {
              return { ...selected, p14: "betted" };
            }
          });
        }
        if (num === 5) {
          setSelected((selected) => {
            if (selected.p15 === "betted") {
              return { ...selected, p15: "" };
            } else {
              return { ...selected, p15: "betted" };
            }
          });
        }
        if (num === 6) {
          setSelected((selected) => {
            if (selected.p16 === "betted") {
              return { ...selected, p16: "" };
            } else {
              return { ...selected, p16: "betted" };
            }
          });
        }
        setFirstBest((value) => {
          if (value.includes(num)) {
            const numBetted = value
              .split("")
              .filter((item) => item != num)
              .join("");
            return numBetted;
          } else {
            return value + num;
          }
        });
      } else if (place === 2) {
        if (num === 1) {
          setSelected((selected) => {
            if (selected.p21 === "betted") {
              return { ...selected, p21: "" };
            } else {
              return { ...selected, p21: "betted" };
            }
          });
        }
        if (num === 2) {
          setSelected((selected) => {
            if (selected.p22 === "betted") {
              return { ...selected, p22: "" };
            } else {
              return { ...selected, p22: "betted" };
            }
          });
        }
        if (num === 3) {
          setSelected((selected) => {
            if (selected.p23 === "betted") {
              return { ...selected, p23: "" };
            } else {
              return { ...selected, p23: "betted" };
            }
          });
        }
        if (num === 4) {
          setSelected((selected) => {
            if (selected.p24 === "betted") {
              return { ...selected, p24: "" };
            } else {
              return { ...selected, p24: "betted" };
            }
          });
        }
        if (num === 5) {
          setSelected((selected) => {
            if (selected.p25 === "betted") {
              return { ...selected, p25: "" };
            } else {
              return { ...selected, p25: "betted" };
            }
          });
        }
        if (num === 6) {
          setSelected((selected) => {
            if (selected.p26 === "betted") {
              return { ...selected, p26: "" };
            } else {
              return { ...selected, p26: "betted" };
            }
          });
        }
        setSecondBet((value) => {
          if (value.includes(num)) {
            const numBetted = value
              .split("")
              .filter((item) => item != num)
              .join("");
            return numBetted;
          } else {
            return value + num;
          }
        });
      } else if (place === 3) {
        if (num === 1) {
          setSelected((selected) => {
            if (selected.p31 === "betted") {
              return { ...selected, p31: "" };
            } else {
              return { ...selected, p31: "betted" };
            }
          });
        }
        if (num === 2) {
          setSelected((selected) => {
            if (selected.p32 === "betted") {
              return { ...selected, p32: "" };
            } else {
              return { ...selected, p32: "betted" };
            }
          });
        }
        if (num === 3) {
          setSelected((selected) => {
            if (selected.p33 === "betted") {
              return { ...selected, p33: "" };
            } else {
              return { ...selected, p33: "betted" };
            }
          });
        }
        if (num === 4) {
          setSelected((selected) => {
            if (selected.p34 === "betted") {
              return { ...selected, p34: "" };
            } else {
              return { ...selected, p34: "betted" };
            }
          });
        }
        if (num === 5) {
          setSelected((selected) => {
            if (selected.p35 === "betted") {
              return { ...selected, p35: "" };
            } else {
              return { ...selected, p35: "betted" };
            }
          });
        }
        if (num === 6) {
          setSelected((selected) => {
            if (selected.p36 === "betted") {
              return { ...selected, p36: "" };
            } else {
              return { ...selected, p36: "betted" };
            }
          });
        }

        setThirdBet((value) => {
          if (value.includes(num)) {
            const numBetted = value
              .split("")
              .filter((item) => item != num)
              .join("");
            return numBetted;
          } else {
            return value + num;
          }
        });
      }
      setLastTouch(parseInt(`${place}${num}`));
      setPressDuration(new Date().getTime());
    } else {
      // alert('Same Touch')
    }
  };
  /**Agregar a jugada */
  const AddBetting = (type) => {
    setShowTextArea(false);
    const bodyBet = {
      dog: { firt: firstBet, second: secondBet, third: thirtBet },
      amount: betted > 0 ? betted : 25,
      type: type,
      run: run,
    };

    if (bodyBet.amount <= 1000 && bodyBet.amount > 0) {
      if (bodyBet.amount) {
        if (betting.some((item) => item.type === bodyBet.type && item.dog.firt === bodyBet.dog.firt && item.dog.second === bodyBet.dog.second && item.dog.third === bodyBet.dog.third)) {
          alert("Jugada Repetida");
        } else {
          // if(bodyBet.dog.firt.length !=1 || bodyBet.dog.second.length >1) return alert('Jugada No Valida: ' + bodyBet.dog.firt+ ' ' +bodyBet.dog.second)
          const bettedPlusAMount = CalcularApostado(bodyBet, 6);
          setTotal((total) => bettedPlusAMount.amount + total);
          betting.push(bodyBet);
          bettedPlusAMount.bodyBet.forEach((item) => bettingShow.push(item));
          setFirstBest("");
          setSecondBet("");
          setThirdBet("");
          setSelected({
            p11: "",
            p12: "",
            p13: "",
            p14: "",
            p15: "",
            p16: "",
            p21: "",
            p22: "",
            p23: "",
            p24: "",
            p25: "",
            p26: "",
            p31: "",
            p32: "",
            p33: "",
            p34: "",
            p35: "",
            p36: "",
          });
          setBetted(0);
          setNewTicketList([]);
          const bettingShowOnly = [];
          bettingShow.forEach((item) => {
            if (!bettingShowOnly.some((y) => y.dog.firt == item.dog.firt && y.dog.second == item.dog.second)) bettingShowOnly.push(item);
          });
          bettingShowOnly.forEach((z, index) => {
            let win;
            let pay;
            let dog;
            if (z.dog.firt !== "" && z.dog.second === "" && z.dog.third === "") {
              pay = z.run.line.d[`p${z.dog.firt}`];
              win = pay * z.amount;
              dog = `G-${z.dog.firt}`;

              newTicket.push({
                amount: z.amount,
                pay: pay.toFixed(2),
                dog,
                win: parseInt(win).toLocaleString("en-EN"),
              });
            } else if (z.dog.firt !== "" && z.dog.second !== "" && z.dog.third === "") {
              pay = z.run.line[`p${z.dog.firt}${z.dog.second}`];
              win = pay * z.amount;
              dog = `P-${z.dog.firt}-${z.dog.second}`;
              newTicket.push({
                amount: z.amount,
                pay: parseFloat(pay).toFixed(2),
                dog,
                win: parseInt(win).toLocaleString("en-EN"),
              });
              /* if (z.type.includes('ATRA')) {
                dog = `P-${z.dog.second}-${z.dog.firt}`
                newTicket.push({
                  amount: z.amount,
                  pay:parseFloat(pay).toFixed(2),
                  dog,
                  win:parseInt(win).toLocaleString('en-EN') 
                })
              }*/
            } else if (z.dog.firt !== "" && z.dog.second !== "" && z.dog.third !== "") {
              pay = z.type.includes("EXAC") ? parseFloat(z.run.line[`p${z.dog.firt}${z.dog.second}`]) * z.run.line.d[`p${z.dog.third}`] : (parseFloat(z.run.line[`p${z.dog.firt}${z.dog.second}`]) * z.run.line.d[`p${z.dog.third}`]) / 6.89;
              win = pay * z.amount;
              dog = z.type.includes("EXAC") ? `TE-${z.dog.firt}-${z.dog.second}-${z.dog.third}` : `TC-${z.dog.firt}-${z.dog.second}-${z.dog.third}`;
              newTicket.push({
                amount: z.amount,
                pay: parseFloat(pay).toFixed(2),
                dog,
                win: parseInt(win).toLocaleString("en-EN"),
              });
              /* if (z.type.includes('ATRA')) {
                dog = `P-${z.dog.second}-${z.dog.firt}`
                newTicket.push({
                  amount: z.amount,
                  pay:parseFloat(pay).toFixed(2),
                  dog,
                  win:parseInt(win).toLocaleString('en-EN') 
                })
              }*/
            } else {
              alert("Jugada Erronea, Favor Revise y Trate de Nuevo");
            }
          });
          setNewTicketList(newTicket.reverse());
        }
      } else {
        setBetted(0);
        alert("Debes Seleccionar el monto apostado...");
      }
      setActive(true);
    } else {
      alert("Monto No Valido");
    }
  };
  /**Seleccionar el tipo de apuesta  DIRECTO, PATRA Y PALANTE, CON TODO*/

  /**Eliminar */
  const Eliminar = () => {
    if (inTeclado !== "") {
      setShow(true);
    } else {
      setSelected({
        p11: "",
        p12: "",
        p13: "",
        p14: "",
        p15: "",
        p16: "",
        p21: "",
        p22: "",
        p23: "",
        p24: "",
        p25: "",
        p26: "",
        p31: "",
        p32: "",
        p33: "",
        p34: "",
        p35: "",
        p36: "",
      });
      betting = [];
      setTotal(() => 0);
      setFirstBest("");
      setSecondBet("");
      setBetted(0);
      setNewTicketList([]);
      newTicket = [];
      bettingShow = [];
    }
  };

  /**Eliminar Ticket */
  const EliminarTicket = () => {
    setShowTextArea(true);
    fetch(`${url}/ticket/pay`, {
      method: "post",
      headers: headers,
      body: JSON.stringify({ ticket: inTeclado, action: "Delete" }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (typeof data != "string" && !data.msg) {
          setWinner("");
          setWinner(() => data.amountToPay);
        } else if (typeof data == "string") {
          setWinner("");
          setWinner(() => data);
        } else if (data.msg && data.msg.includes("agado")) {
          print = data.ticket;
          setWinner(data.msg);
        }
        if (showTicket === true) {
          setTimeout(() => setShowTicket(false), 150);
          Clear();
        }
        setShow(false);
      });
  };
  /**Realizar apuesta */
  const ToPrint = () => {
    if (localStorage.getItem("level") !== "4") return (window.location.href = "/");
    if (active === true) {
      setActive(false);
      fetch(`${url}/ticket`, {
        method: "post",
        headers: headers,
        body: JSON.stringify(betting),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if ((typeof data === "string" && data.includes("Error")) || (data.message && data.message.includes("allida"))) {
            // localStorage.clear()
            // window.location.href = '/'
          } else {
            setNewTicketList([]);
            setSelected({
              p11: "",
              p12: "",
              p13: "",
              p14: "",
              p15: "",
              p16: "",
              p21: "",
              p22: "",
              p23: "",
              p24: "",
              p25: "",
              p26: "",
              p31: "",
              p32: "",
              p33: "",
              p34: "",
              p35: "",
              p36: "",
            });
            betting = [];
            bettingShow = [];
            setTotal(0);
            setBetted(0);
            contentPrint = "";
            print = data;
            setPrintSt(data);
          }
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    }
  };

  /**Entrada por teclado */
  const InputTeclado = (num) => {
    setInTeclado((value) => `${value}${num}`);
  };
  /**Agregar a monto de apuesta */
  const AddBetAmount = (value) => {
    if (betted + value <= 1000) {
      setBetted((betted) => parseFloat(betted) + parseFloat(value));
    } else {
      alert("Apuesta Maxima Superada");
    }
  };
  /**Eliminar el ultimo caracter */
  const Back = () => {
    setInTeclado((value) => value.substring(0, value.length - 1));
  };

  /**Limpia el input del ticket */
  const Clear = () => {
    setInTeclado("");
  };
  /**Enter Para Pago */
  const EnterPay = (action) => {
    setShowTextArea(true);
    setShowTicket(false);
    fetch(`${url}/ticket/pay`, {
      method: "post",
      headers: headers,
      body: JSON.stringify({ ticket: inTeclado, action }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (typeof data != "string" && !data.msg) {
          setWinner("");
          setWinner(() => parseInt(data.amountToPay));
        } else if (typeof data == "string") {
          setWinner("");
          setWinner(() => data);
          Clear();
        } else if (data.msg && data.msg.includes("agado")) {
          print = data.ticket;
          setPrintSt(() => data.ticket);
          setWinner(data.msg);
          Clear();
        } else {
          if (data.hasOwnProperty("msg") && data.msg.includes("petid")) {
            setWinner(data.msg);
            print = data.ticket;
            setPrintSt(() => data.ticket);
            setInTeclado("");
          }
        }
      });
  };

  /** KONVAS */

  const buttons = [
    { number: 1, x: 50, color: `white`, border: `blue`, bg: `red` },
    { number: 2, x: 170, color: `red`, border: `blue`, bg: `blue` },
    { number: 3, x: 290, color: `black`, border: `blue`, bg: `white` },
    { number: 4, x: 410, color: `white`, border: `blue`, bg: `black` },
    { number: 5, x: 530, color: `black`, border: `blue`, bg: `orange` },
    { number: 6, x: 650, color: `yellow`, border: `blue`, bg: `` },
  ];

  const money = [
    { number: 25, x: 50, color: `white`, border: `blue`, bg: `red` },
    { number: 50, x: 50, color: `red`, border: `blue`, bg: `` },
    { number: 100, x: 50, color: `black`, border: `blue`, bg: `white` },
    { number: 200, x: 50, color: `white`, border: `blue`, bg: `blue` },
  ];

  const handleTouchMove = (e) => {
    if (isPressing) {
      const { x, y } = e.target.getStage().getPointerPosition();
      buttons.forEach(({ number }) => {
        for (let layer = 1; layer <= 3; layer++) {
          const rectX = widthCanvas * 0.07 + ((number - 1) * widthCanvas * 0.6) / 6;
          const rectY = heightCanvas * (0.3 + (layer - 1) * 0.12);
          const rectWidth = sizeRef * 0.1;
          const rectHeight = sizeRef * 0.1;
          if (x > rectX && x < rectX + rectWidth && y > rectY && y < rectY + rectHeight) {
            addValue(layer, number);
          }
        }
      });
    }
  };

  // const addValue = (layer, number) => {
  //   setSelected(prevSelected => ({
  //     ...prevSelected,
  //     [`p${layer}${number}`]: prevSelected[`p${layer}${number}`] === 'betted' ? '': 'betted'
  //   }));
  // };

  const NavigatorComp = (path) => {
    window.location.href = path;
  };

  const handleMouseMove = (e) => {
    if (isPressing) {
      const { x, y } = e.target.getStage().getPointerPosition();
      buttons.forEach(({ number }) => {
        for (let layer = 1; layer <= 3; layer++) {
          const rectX = widthCanvas * 0.05 + ((number - 1) * widthCanvas * 0.7) / 6;
          const rectY = heightCanvas * (0.3 + (layer - 1) * 0.12);
          const rectWidth = sizeRef * 0.1;
          const rectHeight = sizeRef * 0.1;
          if (x > rectX && x < rectX + rectWidth && y > rectY && y < rectY + rectHeight) {
            addValue(layer, number);
          }
        }
      });
    }
  };
  const handleMouseDown = () => {
    clearTimeout(timeoutRef.current); // Clear any existing timeout
    setPressStartTime(new Date());
  };

  const handleMouseUp = () => {
    if (pressStartTime) {
      const pressEndTime = new Date();
      const duration = pressEndTime - pressStartTime;
      setPressDuration(duration);
      setPressStartTime(null);
    }
  };

  const handleTouchStart = () => {
    // clearTimeout(timeoutRef.current); // Clear any existing timeout
    // setIsTouching(true);
    // setPressStartTime(new Date());
  };

  const handleTouchEnd = () => {
    setLastTouch(parseInt());
  };

//OBTINE EL ULTIMO TICKET DE UN PUNTO
const GetLastTicket = ()=>{
  fetch(`${url}/ticket/last`,{
    method: 'GET',
    headers: headers
  }).then(res=>res.json())
  .then(data=>{
    setInTeclado(data.num)
    Eliminar()
  })
}

  if (!imagesLoaded) {
    return <div className="container fs-3 text-center text-warning">Loading...</div>; // Mostrar un indicador de carga mientras se cargan las imágenes
  }
  /**Mostrar  */

  if (show === "line") {
    return <Dashboad />;
  } else {
    if (time) {
      return (
        <div className="container-fluid vh-100 vw-100">
          <div className="yPrint print text-center">
            <div className="p-0 m-0">
              {print && print.winner ? (
                ""
              ) : print && print.codeRun ? (
                <div className="p-0 m-0">
                  <div className="col-12 justify-content-center p-0 m-0">
                    <div className="divLogoPrint align-midle">
                      <img className="imgLogoPrint " src={imgLogo} />
                    </div>
                    <div className="col-12">
                      <Barcode
                        value={print ? print.num : "000000"}
                        options={{
                          format: "code128",
                          height: 25,
                          displayValue: false,
                        }}
                        renderer="svg"
                      />
                    </div>
                  </div>
                  <h6>{print ? print.pv : ""}</h6>
                  <h6>Ticket No.: {print ? print.num : ""}</h6>
                  <h6>Carrera: {print ? `D6-${print.codeRun}` : ""}</h6>
                  <h6>Fecha: {print ? `${new Date(print.date).toLocaleDateString("es-ES")} ${new Date(print.date).getHours().toString().padStart(2, "0")}:${new Date(print.date).getMinutes().toString().padStart(2, "0")}:${new Date(print.date).getSeconds().toString().padStart(2, "0")}` : ""}</h6>
                </div>
              ) : (
                <div className="p-0 m-0">
                  <div className="col-12 justify-content-center p-0 m-0">
                    <div className=" col-12 divLogoPrint align-midle">
                      <img className="imgLogoPrint " src={imgLogo} />
                    </div>
                    <div className="col-12">
                      <Barcode
                        value={print ? print.num : "000000"}
                        options={{
                          format: "code128",
                          height: 25,
                          displayValue: false,
                        }}
                        renderer="svg"
                      />
                    </div>
                  </div>
                  <h6>{new Date().getHours()}</h6>
                  <h6>Ticket No.: {new Date().getMinutes()}</h6>
                  <h6>Carrera: {new Date().getSeconds()}</h6>
                  <h6>Fecha: {new Date().toLocaleDateString("es-ES")}</h6>
                </div>
              )}
              {print && print.winner ? (
                <div className="text-center">
                  <h3>Monto Ganador:</h3>
                  <h3>{parseInt(print.winner.amountToPay).toLocaleString("en-EN", { maximumFractionDigits: 0 })}</h3>
                  <div>.</div>
                </div>
              ) : (
                <div>
                  <table className="table table-sm col-12 p-0 m-0 lh-1">
                    <thead>
                      <tr>
                        <th>Jugadas</th>
                        <th>Apostado</th>
                        <th>Paga</th>
                        <th>Ganacia</th>
                      </tr>
                    </thead>
                    <tbody>
                      {print
                        ? print.betted.map((z) => (
                            <tr key={z.code} className="p-0 m-0">
                              <td className="p-0 m-0">{z.dog}</td>
                              <td className="p-0 m-0">
                                {parseInt(z.betted).toLocaleString("en-EN", {
                                  maximumFractionDigits: 1,
                                })}
                              </td>
                              <td className="p-0 m-0">
                                {parseFloat(z.paying).toLocaleString("en-EN", {
                                  maximumFractionDigits: 1,
                                })}
                              </td>
                              <td className="p-0 m-0">
                                {parseInt(z.win).toLocaleString("en-EN", {
                                  maximumFractionDigits: 1,
                                })}
                              </td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </table>
                  <h3>Total Apostado: {print ? print.amount : ""}</h3>
                  <div>.</div>
                </div>
              )}
            </div>
          </div>

          <div className="noPrint">
            <Modal show={show} onHide={!show} size="sm" className="" centered>
              <Modal.Header closeButton></Modal.Header>
              <form>
                <Modal.Body>
                  <h3>Desea Anular Tickey {inTeclado}?</h3>
                </Modal.Body>
                <Modal.Footer className="col-12 row justify-content-between">
                  <div className="col">
                    {" "}
                    <button type="button" className="btn btn-warning form-control" onClick={() => setShow(false)}>
                      Cerrar
                    </button>
                  </div>
                  <div className="col">
                    {" "}
                    <button type="button" onClick={EliminarTicket} className="btn btn-danger form-control">
                      {" "}
                      Aceptar{" "}
                    </button>
                  </div>
                </Modal.Footer>
              </form>
            </Modal>
            <Modal show={showTicket} onHide={!showTicket} size="" className="noPrint" centered>
              <Modal.Header>
                <button className="btn btn-close" onClick={() => setShowTicket(false)}></button>
              </Modal.Header>
              <form>
                <Modal.Body className="col-12 text-center fs-1">Ticket: {inTeclado}</Modal.Body>
                <Modal.Footer>
                  <div className="col-12 row">
                    <div className="col">
                      <input className="btn btn-primary form-control fs-3" type="button" onClick={() => EnterPay("pay")} onTouchStart={() => EnterPay("pay")} value={"Pagar"} />
                    </div>

                    <div className="col">
                      <input type="button" className="form-control btn btn-danger fs-3" onClick={Eliminar} value="Eliminar" />
                    </div>
                    <div className="col">
                      <button type="button" className="form-control btn btn-warning fs-3" onClick={() => EnterPay("repeat")}>
                        Repetir
                      </button>
                    </div>
                  </div>
                </Modal.Footer>
              </form>
            </Modal>
            {state ? (
              <Stage width={widthCanvas} height={heightCanvas} onTouchMove={handleTouchMove}>
                {/**Layer Header */}
                <Layer>
                  <Group width={widthCanvas} height={heightCanvas * 0.08} x={0} y={0}>
                    {/* Fondo azul */}
                    <Rect
                      width={widthCanvas}
                      height={heightCanvas * 0.08}
                      x={0}
                      y={0}
                      fill="blue" // Color de fondo azul
                    />

                    {/* Rectángulos con nombres */}
                    {[
                      { name: "Venta", x: wt, to: "/bet" },
                      { name: "Linea", x: wt, to: "/line" },
                      { name: "Historial", x: wt },
                      { name: "Volumen de Venta", x: wt, to: "/venta" },
                      { name: "Jugadores", x: wt, to: "/player" },
                      { name: "DOGS 8", x: wt, to: "https://dogs8.corriendogs.com/bet" },
                      // { name: "Loteria", x: wt, to: "/lotery-bet" },
                    ].map(({ name, x, to }, index) => (
                      <Link to={to}>
                        <Group width={widthCanvas * 0.15} height={heightCanvas * 0.06} x={widthCanvas * 0.15 * index} y={0} onTouchStart={() => NavigatorComp(to)} onClick={() => NavigatorComp(to)}>
                          <React.Fragment key={index}>
                            {/* Rectángulo */}
                            <Rect
                              x={0}
                              y={heightCanvas * 0.01}
                              width={widthCanvas * 0.12}
                              height={heightCanvas * 0.05}
                              fill="red"
                              stroke="black"
                              strokeWidth={5}
                              cornerRadius={20} // Bordes redondeados
                            />
                            {/* Texto con nombre */}
                            <Text
                              text={name}
                              fontSize={sizeRef * 0.02}
                              fill="white"
                              x={0}
                              y={heightCanvas * 0.01}
                              width={widthCanvas * 0.12}
                              height={heightCanvas * 0.05}
                              align="center"
                              verticalAlign="middle"
                              padding={sizeRef * 0.01} // Ajuste del texto dentro del rectángulo
                              stroke={"white"}
                              strokeWidth={1.2}
                              fontFamily="Verdana"
                            />
                          </React.Fragment>
                        </Group>
                      </Link>
                    ))}
                  </Group>
                </Layer>
                {/**Layer Generico */}
                <Layer>
                  <Group x={0} y={heightCanvas * 0.071} width={widthCanvas} height={heightCanvas * 0.1}>
                    <Group x={0} y={heightCanvas * 0.02} width={widthCanvas * 0.2} height={heightCanvas * 0.1}>
                      <Text text="Carrera" x={0} y={0} width={widthCanvas * 0.2} height={heightCanvas * 0.03} fontSize={sizeRef * 0.03} fontFamily="Arial" fill="white" align="center" verticalAlign="top" />
                      <Text text={run ? run.code : ""} x={0} y={heightCanvas * 0.03} width={widthCanvas * 0.2} height={heightCanvas * 0.1} fontSize={sizeRef * 0.04} fontFamily="Arial" fill="white" align="center" verticalAlign="top" />
                    </Group>
                    <Group x={widthCanvas * 0.25} y={heightCanvas * 0.02} width={widthCanvas * 0.2} height={heightCanvas * 0.1}>
                      <Text text="Inicia" x={0} y={0} width={widthCanvas * 0.2} height={heightCanvas * 0.03} fontSize={sizeRef * 0.03} fontFamily="Arial" fill="white" align="center" verticalAlign="top" />
                      <Text text={nextRun} x={0} y={heightCanvas * 0.03} width={widthCanvas * 0.2} height={heightCanvas * 0.1} fontSize={sizeRef * 0.04} fontFamily="Arial" fill="white" align="center" verticalAlign="top" />
                    </Group>
                    <Group x={widthCanvas * 0.5} y={heightCanvas * 0.02} width={heightCanvas * 0.2} height={heightCanvas * 0.1}>
                      <Text text="Actual" x={0} y={0} width={widthCanvas * 0.2} height={heightCanvas * 0.1} fontSize={sizeRef * 0.03} fontFamily="Arial" fill="white" align="center" verticalAlign="top" />
                      <Text text={hour} x={0} y={heightCanvas * 0.03} width={widthCanvas * 0.2} height={heightCanvas * 0.1} fontSize={sizeRef * 0.04} fontFamily="Arial" fill="white" align="center" verticalAlign="top" />
                    </Group>
                    <Group x={widthCanvas * 0.7} y={heightCanvas * 0.02} width={widthCanvas * 0.15} height={heightCanvas * 0.1} onClick={GetLastTicket}>
                      <Rect x={0} y={0} width={widthCanvas * 0.15} height={heightCanvas * 0.1} fill="gray" cornerRadius={20} stroke={'black'} strokeWidth={10} />
                      <Text text={"Eliminar Ultimo Ticket"} x={0} y={0} width={widthCanvas * 0.13} height={heightCanvas * 0.1} fontSize={sizeRef * 0.03} fontFamily="Arial" fill="yellow" align="center" verticalAlign="middle" />
                    </Group>
                    <Group x={widthCanvas * 0.80} y={heightCanvas * 0.02} width={widthCanvas * 0.2} height={heightCanvas * 0.1}>
                      <Text text="Segundos" x={0} y={0} width={widthCanvas * 0.2} height={heightCanvas * 0.1} fontSize={sizeRef * 0.03} fontFamily="Arial" fill="white" align="center" verticalAlign="top" />
                      <Text text={time} x={0} y={heightCanvas * 0.03} width={widthCanvas * 0.2} height={heightCanvas * 0.1} fontSize={sizeRef * 0.04} fontFamily="Arial" fill="white" align="center" verticalAlign="top" />
                    </Group>
                  </Group>
                  {/** Data Bet and Ticket*/}

                  <Group x={widthCanvas * 0.1} y={heightCanvas * 0.18} width={widthCanvas * 0.6} height={heightCanvas * 0.071}>
                    <Group x={0} y={0} width={widthCanvas * 0.1} height={heightCanvas * 0.071}>
                      <Text x={0} y={0} text="Valor Jugada Actual" width={widthCanvas * 0.3} height={heightCanvas * 0.03} fill="white" fontSize={sizeRef * 0.03} verticalAlign="botton" />
                      <Rect x={0} y={heightCanvas * 0.035} width={widthCanvas * 0.2} height={heightCanvas * 0.06} fill="white" cornerRadius={10} />
                      <Text text={betted} x={0} y={heightCanvas * 0.035} fontSize={sizeRef * 0.05} width={widthCanvas * 0.2} height={heightCanvas * 0.06} fill="black" align="center" verticalAlign="middle" />
                    </Group>
                    <Group x={widthCanvas * 0.3} y={0} width={widthCanvas * 0.1} height={heightCanvas * 0.071}>
                      <Text text="Valor Ticket Actual" x={0} y={0} width={widthCanvas * 0.3} height={heightCanvas * 0.03} fill="white" fontSize={sizeRef * 0.03} verticalAlign="botton" />
                      <Rect x={0} y={heightCanvas * 0.035} width={widthCanvas * 0.2} height={heightCanvas * 0.06} fill="white" cornerRadius={10} />
                      <Text text={total} x={0} y={heightCanvas * 0.035} fontSize={sizeRef * 0.05} width={widthCanvas * 0.2} height={heightCanvas * 0.06} fill="black" align="center" verticalAlign="middle" />
                    </Group>
                  </Group>
                </Layer>

                <Layer>
                  {/* Layer BOTTONES 1 */}
                  <Group x={0} y={heightCanvas * 0.3} width={widthCanvas * 0.7} height={heightCanvas * 0.1}>
                    <Group x={0} y={0} width={widthCanvas * 0.05} height={heightCanvas * 0.1}>
                      <Text text="1" fontSize={sizeRef / 10} fill="white" x={0} y={0} align="left" verticalAlign="middle" width={widthCanvas * 0.05} height={heightCanvas * 0.1} />
                      <Text text="0" fontSize={sizeRef / 20} fill="white" x={0} y={0} align="right" verticalAlign="top" width={widthCanvas * 0.05} height={heightCanvas * 0.1} />
                    </Group>
                    {buttons.map(({ number, color, border, bg }, index) => (
                      <React.Fragment key={`1${number}`}>
                        <Group x={widthCanvas * 0.07 + (index * widthCanvas * 0.6) / 6} y={0} width={sizeRef * 0.1} height={sizeRef * 0.1}>
                          <Rect x={0} y={0} width={sizeRef * 0.1} height={sizeRef * 0.1} fill={selected[`p1${number}`] === "betted" ? "gray" : bg} stroke={border} strokeWidth={2} cornerRadius={10} onTouchStart={() => addValue(1, number)} onClick={() => addValue(1, number)} />
                          <Text text={number.toString()} fontSize={sizeRef / 10} fill={selected[`p1${number}`] === "betted" ? "brown" : color} x={0} y={0} align="center" verticalAlign="middle" width={sizeRef * 0.1} height={sizeRef * 0.1} onTouchStart={() => addValue(1, number)} onClick={() => addValue(1, number)} />
                        </Group>
                      </React.Fragment>
                    ))}
                  </Group>

                  {/* Layer BOTTONES 2 */}
                  <Group x={0} y={heightCanvas * 0.42} width={widthCanvas * 0.7} height={heightCanvas * 0.1}>
                    <Group x={0} y={0} width={widthCanvas * 0.05} height={heightCanvas * 0.1}>
                      <Text text="2" fontSize={sizeRef / 10} fill="white" x={0} y={0} align="left" verticalAlign="middle" width={widthCanvas * 0.05} height={heightCanvas * 0.1} />
                      <Text text="0" fontSize={sizeRef / 20} fill="white" x={0} y={0} align="right" verticalAlign="top" width={widthCanvas * 0.05} height={heightCanvas * 0.1} />
                    </Group>
                    {buttons.map(({ number, color, border, bg }, index) => (
                      <React.Fragment key={`2${number}`}>
                        <Group x={widthCanvas * 0.07 + (index * widthCanvas * 0.6) / 6} y={0} width={sizeRef * 0.1} height={sizeRef * 0.1}>
                          <Rect x={0} y={0} width={sizeRef * 0.1} height={sizeRef * 0.1} fill={selected[`p2${number}`] === "betted" ? "gray" : bg} stroke={border} strokeWidth={2} cornerRadius={10} onTouchStart={() => addValue(2, number)} onClick={() => addValue(2, number)} />
                          <Text text={number.toString()} fontSize={sizeRef / 10} fill={selected[`p2${number}`] === "betted" ? "brown" : color} x={0} y={0} align="center" verticalAlign="middle" width={sizeRef * 0.1} height={sizeRef * 0.1} onTouchStart={() => addValue(2, number)} onClick={() => addValue(2, number)} />
                        </Group>
                      </React.Fragment>
                    ))}
                  </Group>

                  {/* Layer BOTTONES 3 */}
                  <Group x={0} y={heightCanvas * 0.54} width={widthCanvas * 0.7} height={heightCanvas * 0.1}>
                    <Group x={0} y={0} width={widthCanvas * 0.07} height={heightCanvas * 0.1}>
                      <Text text="3" fontSize={sizeRef / 10} fill="white" x={0} y={0} align="left" verticalAlign="middle" width={widthCanvas * 0.05} height={heightCanvas * 0.1} />
                      <Text text="0" fontSize={sizeRef / 20} fill="white" x={0} y={0} align="right" verticalAlign="top" width={widthCanvas * 0.05} height={heightCanvas * 0.1} />
                    </Group>
                    {buttons.map(({ number, color, border, bg }, index) => (
                      <React.Fragment key={`3${number}`}>
                        <Group x={widthCanvas * 0.07 + (index * widthCanvas * 0.6) / 6} y={0} width={sizeRef * 0.1} height={sizeRef * 0.1}>
                          <Rect x={0} y={0} width={sizeRef * 0.1} height={sizeRef * 0.1} fill={selected[`p3${number}`] === "betted" ? "gray" : bg} stroke={border} strokeWidth={2} cornerRadius={10} onClickn={() => addValue(3, number)} onTouchStart={() => addValue(3, number)} />
                          <Text text={number.toString()} fontSize={sizeRef / 10} fill={selected[`p3${number}`] === "betted" ? "brown" : color} x={0} y={0} align="center" verticalAlign="middle" width={sizeRef * 0.1} height={sizeRef * 0.1} onTouchStart={() => addValue(3, number)} onClick={() => addValue(3, number)} />
                        </Group>
                      </React.Fragment>
                    ))}
                  </Group>
                </Layer>
                {/**money */}
                <Layer>
                  <Group x={0} y={heightCanvas * 0.67} width={widthCanvas * 0.7} height={heightCanvas * 0.12}>
                    {money.map(({ number, x, color, border, bg }, index) => (
                      <React.Fragment key={number}>
                        <Group x={widthCanvas * 0.05 + index * widthCanvas * 0.13} y={0} width={widthCanvas * 0.12} height={heightCanvas * 0.12}>
                          <Rect x={0} y={0} width={widthCanvas * 0.12} height={heightCanvas * 0.12} fill={"green"} stroke={border} strokeWidth={2} cornerRadius={10} onClick={() => AddBetAmount(number)} onTouchStart={() => AddBetAmount(number)} />
                          <Text text={number.toString()} fontSize={sizeRef / 12.5} fill={"white"} x={0} y={0} align="center" verticalAlign="middle" width={widthCanvas * 0.12} height={heightCanvas * 0.12} onClick={() => AddBetAmount(number)} onTouchStart={() => AddBetAmount(number)} />
                        </Group>
                      </React.Fragment>
                    ))}
                    {btnEnter && <KonvaImage image={btnEnter} x={widthCanvas * 0.62 - sizeRef * 0.1} y={0} width={sizeRef * 0.2} height={sizeRef * 0.12} onClick={() => EnterPay("pay")} onTouchStart={() => EnterPay("pay")} />}
                  </Group>
                  {/**Option  */}
                  <Group x={widthCanvas * 0.05} y={heightCanvas - heightCanvas * 0.18} width={widthCanvas * 0.7} height={heightCanvas * 0.15}>
                    {btnOption && btnOption.length ? (
                      btnOption.map(({ x, text, img }, index) => (
                        <Group key={text} x={(widthCanvas * index * 0.7) / 8} y={0} width={(widthCanvas * index * 0.7) / 8} height={heightCanvas * 0.15}>
                          <KonvaImage image={img} x={0} y={0} width={((widthCanvas * 0.7) / 8) * 0.9} height={heightCanvas * 0.1} onTouchStart={() => (text.includes("DIREC") || text.includes("EXAC") || text.includes("COLOC") || text.includes("PATRA") || text.includes("TODO") ? AddBetting(text) : text.includes("PRIN") ? ToPrint() : text.includes("REPETIR") ? EnterPay("repeat") : Eliminar())} onClick={() => (text.includes("DIREC") || text.includes("EXAC") || text.includes("COLOC") || text.includes("PATRA") || text.includes("TODO") ? AddBetting(text) : text.includes("PRIN") ? ToPrint() : text.includes("REPETIR") ? EnterPay("repeat") : Eliminar())} />
                        </Group>
                      ))
                    ) : (
                      <Text text="" />
                    )}
                  </Group>
                </Layer>
                {/**Ticket */}
                <Layer>
                  <Group x={widthCanvas * 0.71} y={heightCanvas * 0.2} width={widthCanvas * 0.25} height={heightCanvas * 0.6}>
                    {/* Grupo 1: Rectángulo con esquinas redondeadas y texto */}
                    <Group x={0} y={0}>
                      <Rect x={widthCanvas * 0.01} y={0} width={widthCanvas * 0.25 * 0.6} height={heightCanvas / 15} fill="white" cornerRadius={10} />
                      <Text text={inTeclado.length > 0 ? inTeclado : "Entre Ticket"} fontSize={sizeRef / 30} width={widthCanvas * 0.25 * 0.6} height={heightCanvas / 15} verticalAlign="middle" fill="black" x={0} y={0} align="center" />
                      {btnRepetir && <KonvaImage image={btnRepetir.img} x={(widthCanvas * 0.15) / 6 + widthCanvas * 0.3 * 0.5} y={-heightCanvas * 0.02} width={widthCanvas * 0.08} height={heightCanvas / 10} onClick={() => EnterPay("repeat")} onTouchStart={() => EnterPay("repeat")} />}
                    </Group>

                    {/* Grupo 2: Matriz 4x4 */}
                    <Group x={0} y={heightCanvas * 0.1}>
                      <Rect x={0} y={-heightCanvas * 0.02} width={widthCanvas * 0.25} height={heightCanvas * 0.465} fill="white" />
                      {["1", "2", "3", "A", "4", "5", "6", "B", "7", "8", "9", "C", "CL", "0", "<--", "D"].map((char, index) => (
                        <Group key={index} x={((index % 4) * widthCanvas * 0.23) / 4 + widthCanvas * 0.01} y={Math.floor(index / 4) * heightCanvas * 0.11} width={(widthCanvas * 0.23) / 4} height={heightCanvas * 0.1}>
                          <Rect x={0} y={0} width={(widthCanvas * 0.22) / 4} height={heightCanvas * 0.1} fill="lightgrey" stroke="black" cornerRadius={5} />
                          <Text text={char} fontSize={sizeRef / 20} width={(widthCanvas * 0.22) / 4} height={heightCanvas * 0.1} fill="black" x={0} y={0} align="center" verticalAlign="middle" onClick={() => (char.includes("-") ? Back() : char.includes("CL") ? Clear() : InputTeclado(char))} onTouchStart={() => (char.includes("-") ? Back() : char.includes("CL") ? Clear() : InputTeclado(char))} />
                        </Group>
                      ))}
                    </Group>

                    {/* Grupo 3: Tabla con encabezado y filas dinámicas */}
                    <Group x={0} y={heightCanvas * 0.55}>
                      <Rect x={0} y={0} width={widthCanvas * 0.25} height={heightCanvas * 0.4} fill="lightblue" cornerRadius={10} />
                      <Group x={widthCanvas * 0.01} y={heightCanvas * 0.01} width={widthCanvas * 0.25} height={heightCanvas * 0.04}>
                        <Text text="#" x={0} width={widthCanvas * 0.02} align="center" fontSize={sizeRef / 50} />
                        <Text text="Jugada" x={widthCanvas * 0.021} width={widthCanvas * 0.05} align="center" fontSize={sizeRef / 50} />
                        <Text text="Apostado" x={widthCanvas * 0.072} width={widthCanvas * 0.06} align="center" fontSize={sizeRef / 50} />
                        <Text text="Gana" x={widthCanvas * 0.123} width={widthCanvas * 0.06} align="center" fontSize={sizeRef / 50} />
                        <Text text="Total" x={widthCanvas * 0.172} width={widthCanvas * 0.05} align="center" fontSize={sizeRef / 50} />
                      </Group>
                      <Group>
                        {showTextArea ? (
                          <Group x={0} y={heightCanvas * 0.03} width={widthCanvas * 0.25}>
                            <Text text={winner.toString()} fontSize={sizeRef / 30} x={0} y={heightCanvas * 0.01} width={widthCanvas * 0.25} align="center" />
                          </Group>
                        ) : (
                          newTicketList.map((row, index) => (
                            <Group key={index} x={widthCanvas * 0.001} y={(index + 1) * heightCanvas * 0.04}>
                              <Text text={index + 1} x={widthCanvas * 0.01} width={widthCanvas * 0.02} align="center" />
                              <Text text={row.dog.toString()} x={widthCanvas * 0.03} width={widthCanvas * 0.05} align="center" />
                              <Text text={row.amount.toString()} x={widthCanvas * 0.08} width={widthCanvas * 0.05} align="center" />
                              <Text text={row.pay.toString()} x={widthCanvas * 0.13} width={widthCanvas * 0.05} align="center" />
                              <Text text={row.win.toString()} x={widthCanvas * 0.18} width={widthCanvas * 0.05} align="center" />
                            </Group>
                          ))
                        )}
                      </Group>
                    </Group>
                  </Group>
                </Layer>
              </Stage>
            ) : (
              <Stage width={window.innerWidth * 0.99} height={window.innerHeight * 0.99}>
                <Layer>
                  <Group x={0} y={0} width={window.innerWidth * 0.99} height={window.innerHeight * 0.99}>
                    <Text x={0} y={0} text="Este Punto Esta Temporalmente Fuera de Servicio, Favor Contactar a su Proveedor" fill="yellow" fontSize={window.innerHeight / 20} align="center" verticalAlign="middle" width={window.innerWidth * 0.99} height={window.innerHeight * 0.99} />
                  </Group>
                </Layer>
              </Stage>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="">
          <header>
            <nav>
              <div className="">
                <Link className="vol venta" to="/bet">
                  VENTA
                </Link>
                <Link to="/line" className="vol lin">
                  LINEA
                </Link>
                <Link className="vol res" to="/history">
                  RESULTADOS
                </Link>
                <Link className="vol volvent" to="/venta">
                  VOLUMEN DE VENTAS
                </Link>
                <Link className="vol volvent" to="/player">
                  JUGADORES
                </Link>
                <button className="boton_menu bg-danger" onClick={Logout}>
                  <DoorOpenFill />
                </button>
              </div>
            </nav>
          </header>
          <div className="suspendid">
            <h1 className="carrAc ">Carrera Activa...</h1>
          </div>
        </div>
      );
    }
  }
}
export default Bet;

// import React, { useState, useEffect, useRef } from "react";
// import "./bet.css";
// import imgPrint from "../../assets/venta/imprimir.png";
// import imgLogo from "../../assets/logo.png";
// import imgDel from "../../assets/venta/ELIMINAR.png";
// import imgRepeat from "../../assets/venta/btnrepetir.png";
// import btnDirect from "../../assets/venta/btndirecto.png";
// import exacta from "../../assets/venta/exacta1.png";
// import colocada from "../../assets/venta/colocada1.png";
// import all from "../../assets/venta/contodos.png";
// import imgEnter from "../../assets/venta/enter2.png";
// import btnAhead from "../../assets/venta/btnpalante.png";
// import { CalcularApostado } from "../../utils/Calculo-apostado";
// import { headers, heightCanvas, url, widthCanvas } from "../../utils/const";
// import Dashboad from "../dashboard/Dashboard";
// import { Logout } from "../../utils/logout";
// import { DoorOpen, DoorOpenFill } from "react-bootstrap-icons";
// import Barcode from "react-jsbarcode";
// import { socket } from "../../io";
// import { Link } from "react-router-dom";
// import Modal from "react-bootstrap/Modal";
// import { addSeconds } from "date-fns";
// import {
//   Stage,
//   Layer,
//   Rect,
//   Circle,
//   Text,
//   Group,
//   Image as KonvaImage,
// } from "react-konva";
// let betting = [];
// const wt = 50;
// let bettingShow = [];
// let print = undefined;

// // Función para cargar una imagen
// const loadImage = (src) => {
//   return new Promise((resolve, reject) => {
//     const img = new window.Image();
//     img.src = src;
//     img.onload = () => resolve(img);
//     img.onerror = (err) => reject(err);
//   });
// };

// function Bet() {
//   const [sizeRef, setSizeRef] = useState(1024)
//   const [firstBet, setFirstBest] = useState("");
//   const [secondBet, setSecondBet] = useState("");
//   const [thirtBet, setThirdBet] = useState("");
//   const [time, setTime] = useState("");
//   const [hour, setHour] = useState("");
//   const [nextRun, setNextRun] = useState("");
//   const [run, setRun] = useState("");
//   const [betted, setBetted] = useState(0);
//   const [selected, setSelected] = useState({
//     p11: "",
//     p12: "",
//     p13: "",
//     p14: "",
//     p15: "",
//     p16: "",
//     p17: "",
//     p18: "",
//     p21: "",
//     p22: "",
//     p23: "",
//     p24: "",
//     p25: "",
//     p26: "",
//     p27: "",
//     p28: "",
//     p31: "",
//     p32: "",
//     p33: "",
//     p34: "",
//     p35: "",
//     p36: "",
//     p37: "",
//     p38: "",
//   });
//   const [total, setTotal] = useState(0);
//   const [inTeclado, setInTeclado] = useState("");
//   const [winner, setWinner] = useState("");
//   const [show, setShow] = useState("");
//   const [active, setActive] = useState(false);
//   const [newTicketList, setNewTicketList] = useState([]);
//   const [showTextArea, setShowTextArea] = useState(false);
//   const [PrintSt, setPrintSt] = useState();
//   const [isPressing, setIsPressing] = useState(true);
//   const [btnEnter, SetBtnEnter] = useState();
//   const [btnRepetir, setBtnRepetir] = useState();
//   const [btnOption, setBtnOption] = useState([]);
//   const [pressStartTime, setPressStartTime] = useState(null);
//   const [pressDuration, setPressDuration] = useState(0);
//   const [isTouching, setIsTouching] = useState(true);
//   const [lastTouch, setLastTouch] = useState()
//   const [showTicket, setShowTicket] = useState(false)
//   const timeoutRef = useRef(null);
//   const [imagesLoaded, setImagesLoaded] = useState(false);
//   const [state, setState] = useState(false)

//   let newTicket = [];
//   let contentPrint ='print'
//   const imgList = [
//     { img: btnDirect, text: "DIRECTO", x: 10, color: `white`, border: `blue`, bg: `red` },
//     { img: btnAhead, text: "PATRA Y PALANTE", x: 10, color: `red`, border: `blue`, bg: `` },
//     { img: all, text: "CON TODOS", x: 10, color: `black`, border: `blue`, bg: `white` },
//     { img: exacta, text: "EXACT", x: 10, color: `white`, border: `blue`, bg: `red` },
//     { img: colocada, text: "COLOCA", x: 10, color: `white`, border: `blue`, bg: `red` },
//     { img: imgDel, text: "ELIMINAR", x: 10, color: `black`, border: `blue`, bg: `orange` },
//     { img: imgPrint, text: "PRINTER", x: 10, color: `yellow`, border: `blue`, bg: `green` },
//   ];
//   useEffect(()=>{
//     socket.emit('state',localStorage.getItem('point'))
//     socket.on('state-res', (data)=>{
//         setState(data.state);
//     })
//   },[])
//   useEffect(() => {
//     window.addEventListener('mouseup', handleMouseUp);
//     return () => {
//       window.removeEventListener('mouseup', handleMouseUp);
//     };
//   }, []);
// useEffect(()=>{
//       if (localStorage.getItem("level") !== "4")
//         return (window.location.href = "/");
// },[])
// /**Determinaa le madida mas peque;a de la pantalla para tomar su medida como referencia */
// useEffect(()=>{
// const w = window.innerWidth;
// const h = window.innerHeight;
// setSizeRef(w<h? w:h)
// },[sizeRef])
//   /**Para cargar imagenes */
//   useEffect(() => {
//     const loadAllImages = async () => {
//       try {
//         const allImgPromises = imgList.map(option =>
//           loadImage(option.img).then(img => ({ ...option, img }))
//         );
//         const allImg = await Promise.all(allImgPromises);
//         setBtnOption(allImg);
//         setImagesLoaded(true); // Marcar que todas las imágenes se han cargado
//       } catch (err) {
//         console.error('Error loading images:', err);
//       }
//     };

//     loadAllImages();
//   }, []);
//   useEffect(() => {
//     const img = new window.Image();
//     img.src = imgEnter;
//     img.onload = () => SetBtnEnter(img);
//   }, [btnEnter]);
// useEffect(()=>{
//     const img = new window.Image();
//     img.src = imgRepeat;
//     img.onload = () => setBtnRepetir({
//       img: img,
//       text: "REPETIR",
//       x: 10,
//       color: `white`,
//       border: `blue`,
//       bg: `blue`,
//     })

// },[])
//   /** Datos de la Carrera actual para cual sera la apuesta */
//   useEffect(() => {
//     if (!localStorage.getItem("user")) return (window.location.href = "/");
//     socket.on("screen", (data) => {
//       setTime((value) => {
//         if (value && value <= 10) {
//           setSelected({
//             p11: "", p12: "", p13: "", p14: "", p15: "", p16: "", p17: "", p18: "",
//             p21: "", p22: "", p23: "", p24: "", p25: "", p26: "", p27: "", p28: "",
//             p31: "", p32: "", p33: "", p34: "", p35: "", p36: "", p37: "", p38: "",
//           });
//           betting = [];
//           setTotal(() => 0);
//           setFirstBest("");
//           setSecondBet("");
//           setBetted(0);
//           setActive(false);
//         }
//         return data.time;
//       });

//       const hr = `${new Date()
//         .getHours()
//         .toString()
//         .padStart(2, "0")}:${new Date()
//         .getMinutes()
//         .toString()
//         .padStart(2, "0")}:${new Date()
//         .getSeconds()
//         .toString()
//         .padStart(2, "0")}`;
//       const lasthours = addSeconds(new Date(), data.time);
//       setHour(() => hr);
//       setNextRun(
//         () =>
//           `${lasthours.getHours().toString().padStart(2, "0")}:${lasthours
//             .getMinutes()
//             .toString()
//             .padStart(2, "0")}:${lasthours
//             .getSeconds()
//             .toString()
//             .padStart(2, "0")}`
//       );
//     });
//     if (PrintSt && PrintSt.num) {
//       if (localStorage.getItem("level") !== "4")
//         return (window.location.href = "/");
//       window.print();
//       setPrintSt(undefined);
//       print = undefined;
//     }
//     /**Socket.Io */
//     socket.on("run", (data) => setRun(data.run));
//     /**Lectura codigo de barra */
//     // Agregar el evento al documento cuando el componente se monta
//     const handleKeyDown = (event) => {
//       if (event.key !== "Enter") {
//         const nume = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
//         if (nume.includes(Number(event.key))) InputTeclado(event.key);
//       } else {
//         if (event.key === "Enter") setShowTicket(true);
//       }
//     };
//     document.addEventListener("keydown", handleKeyDown);
//     // Eliminar el evento al documento cuando el componente se desmonta
//     return () => {
//      // socket.off("screen", handleScreen);
//       socket.off("run", setRun);
//       document.removeEventListener("keydown", handleKeyDown);
//     };
//   }, [PrintSt]);

//   if (!localStorage.getItem("user")) {
//     return (window.location.href = "/");
//   }

//   const addValue = (place, num) => {
//     if(!lastTouch ||(new Date().getTime())-pressDuration>150){

//     if (place === 1) {
//       if (num === 1) {
//         setSelected((selected) => {
//           if (selected.p11 === "betted") {
//             return { ...selected, p11: "" };
//           } else {
//             return { ...selected, p11: "betted" };
//           }
//         });
//       }
//       if (num === 2) {
//         setSelected((selected) => {
//           if (selected.p12 === "betted") {
//             return { ...selected, p12: "" };
//           } else {
//             return { ...selected, p12: "betted" };
//           }
//         });
//       }
//       if (num === 3) {
//         setSelected((selected) => {
//           if (selected.p13 === "betted") {
//             return { ...selected, p13: "" };
//           } else {
//             return { ...selected, p13: "betted" };
//           }
//         });
//       }
//       if (num === 4) {
//         setSelected((selected) => {
//           if (selected.p14 === "betted") {
//             return { ...selected, p14: "" };
//           } else {
//             return { ...selected, p14: "betted" };
//           }
//         });
//       }
//       if (num === 5) {
//         setSelected((selected) => {
//           if (selected.p15 === "betted") {
//             return { ...selected, p15: "" };
//           } else {
//             return { ...selected, p15: "betted" };
//           }
//         });
//       }
//       if (num === 6) {
//         setSelected((selected) => {
//           if (selected.p16 === "betted") {
//             return { ...selected, p16: "" };
//           } else {
//             return { ...selected, p16: "betted" };
//           }
//         });
//       }
//       if (num === 7) {
//         setSelected((selected) => {
//           if (selected.p17 === "betted") {
//             return { ...selected, p17: "" };
//           } else {
//             return { ...selected, p17: "betted" };
//           }
//         });
//       }
//       if (num === 8) {
//         setSelected((selected) => {
//           if (selected.p18 === "betted") {
//             return { ...selected, p18: "" };
//           } else {
//             return { ...selected, p18: "betted" };
//           }
//         });
//       }
//       setFirstBest((value) => {
//         if (value.includes(num)) {
//           const numBetted = value
//             .split("")
//             .filter((item) => item != num)
//             .join("");
//           return numBetted;
//         } else {
//           return value + num;
//         }
//       });
//     } else if (place === 2) {
//       if (num === 1) {
//         setSelected((selected) => {
//           if (selected.p21 === "betted") {
//             return { ...selected, p21: "" };
//           } else {
//             return { ...selected, p21: "betted" };
//           }
//         });
//       }
//       if (num === 2) {
//         setSelected((selected) => {
//           if (selected.p22 === "betted") {
//             return { ...selected, p22: "" };
//           } else {
//             return { ...selected, p22: "betted" };
//           }
//         });
//       }
//       if (num === 3) {
//         setSelected((selected) => {
//           if (selected.p23 === "betted") {
//             return { ...selected, p23: "" };
//           } else {
//             return { ...selected, p23: "betted" };
//           }
//         });
//       }
//       if (num === 4) {
//         setSelected((selected) => {
//           if (selected.p24 === "betted") {
//             return { ...selected, p24: "" };
//           } else {
//             return { ...selected, p24: "betted" };
//           }
//         });
//       }
//       if (num === 5) {
//         setSelected((selected) => {
//           if (selected.p25 === "betted") {
//             return { ...selected, p25: "" };
//           } else {
//             return { ...selected, p25: "betted" };
//           }
//         });
//       }
//       if (num === 6) {
//         setSelected((selected) => {
//           if (selected.p26 === "betted") {
//             return { ...selected, p26: "" };
//           } else {
//             return { ...selected, p26: "betted" };
//           }
//         });
//       }
//       if (num === 7) {
//         setSelected((selected) => {
//           if (selected.p27 === "betted") {
//             return { ...selected, p27: "" };
//           } else {
//             return { ...selected, p27: "betted" };
//           }
//         });
//       }
//       if (num === 8) {
//         setSelected((selected) => {
//           if (selected.p28 === "betted") {
//             return { ...selected, p28: "" };
//           } else {
//             return { ...selected, p28: "betted" };
//           }
//         });
//       }

//       setSecondBet((value) => {
//         if (value.includes(num)) {
//           const numBetted = value
//             .split("")
//             .filter((item) => item != num)
//             .join("");
//           return numBetted;
//         } else {
//           return value + num;
//         }
//       });
//     } else if (place === 3) {
//       if (num === 1) {
//         setSelected((selected) => {
//           if (selected.p31 === "betted") {
//             return { ...selected, p31: "" };
//           } else {
//             return { ...selected, p31: "betted" };
//           }
//         });
//       }
//       if (num === 2) {
//         setSelected((selected) => {
//           if (selected.p32 === "betted") {
//             return { ...selected, p32: "" };
//           } else {
//             return { ...selected, p32: "betted" };
//           }
//         });
//       }
//       if (num === 3) {
//         setSelected((selected) => {
//           if (selected.p33 === "betted") {
//             return { ...selected, p33: "" };
//           } else {
//             return { ...selected, p33: "betted" };
//           }
//         });
//       }
//       if (num === 4) {
//         setSelected((selected) => {
//           if (selected.p34 === "betted") {
//             return { ...selected, p34: "" };
//           } else {
//             return { ...selected, p34: "betted" };
//           }
//         });
//       }
//       if (num === 5) {
//         setSelected((selected) => {
//           if (selected.p35 === "betted") {
//             return { ...selected, p35: "" };
//           } else {
//             return { ...selected, p35: "betted" };
//           }
//         });
//       }
//       if (num === 6) {
//         setSelected((selected) => {
//           if (selected.p36 === "betted") {
//             return { ...selected, p36: "" };
//           } else {
//             return { ...selected, p36: "betted" };
//           }
//         });
//       }
//       if (num === 7) {
//         setSelected((selected) => {
//           if (selected.p37 === "betted") {
//             return { ...selected, p37: "" };
//           } else {
//             return { ...selected, p37: "betted" };
//           }
//         });
//       }
//       if (num === 8) {
//         setSelected((selected) => {
//           if (selected.p38 === "betted") {
//             return { ...selected, p38: "" };
//           } else {
//             return { ...selected, p38: "betted" };
//           }
//         });
//       }

//       setThirdBet((value) => {
//         if (value.includes(num)) {
//           const numBetted = value
//             .split("")
//             .filter((item) => item != num)
//             .join("");
//           return numBetted;
//         } else {
//           return value + num;
//         }
//       });
//     }
//     setLastTouch(parseInt(`${place}${num}`))
//     setPressDuration(new Date().getTime())
//   }else{
//    // alert('Same Touch')
//   }
//   };
//   /**Agregar a jugada */
//   const AddBetting = (type) => {
//     setShowTextArea(false);
//     const bodyBet = {
//       dog: { firt: firstBet, second: secondBet, third: thirtBet },
//       amount: betted>0? betted: 25,
//       type: type,
//       run: run,
//     };

//     if (bodyBet.amount <= 1000 && bodyBet.amount > 0) {
//       if (bodyBet.amount) {
//         if (
//           betting.some(
//             (item) =>
//               item.type == bodyBet.type &&
//               item.dog.firt == bodyBet.dog.firt &&
//               item.dog.second == bodyBet.dog.second
//           )
//         ) {
//           alert("Jugada Repetida");
//         } else {
//           // if(bodyBet.dog.firt.length !=1 || bodyBet.dog.second.length >1) return alert('Jugada No Valida: ' + bodyBet.dog.firt+ ' ' +bodyBet.dog.second)
//           const bettedPlusAMount = CalcularApostado(bodyBet,8);
//           setTotal((total) => bettedPlusAMount.amount + total);
//           betting.push(bodyBet);
//           bettedPlusAMount.bodyBet.forEach((item) => bettingShow.push(item));
//           setFirstBest("");
//           setSecondBet("");
//           setThirdBet("")
//           setSelected({
//             p11: "",
//             p12: "",
//             p13: "",
//             p14: "",
//             p15: "",
//             p16: "",
//             p17: "",
//             p18: "",
//             p21: "",
//             p22: "",
//             p23: "",
//             p24: "",
//             p25: "",
//             p26: "",
//             p27: "",
//             p28: "",
//             p31: "",
//             p32: "",
//             p33: "",
//             p34: "",
//             p35: "",
//             p36: "",
//             p37: "",
//             p38: "",
//           });
//           setBetted(0);
//           setNewTicketList([]);
//           const bettingShowOnly = [];
//           bettingShow.forEach((item) => {
//             if (!bettingShowOnly.some((y) =>y.dog.firt == item.dog.firt && y.dog.second == item.dog.second ))bettingShowOnly.push(item);
//           });
//           bettingShowOnly.forEach((z, index) => {
//             let win;
//             let pay;
//             let dog;
//             if (z.dog.firt !=="" && z.dog.second === "" && z.dog.third==="") {
//               pay = z.run.line.d[`p${z.dog.firt}`];
//               win = pay * z.amount;
//               dog = `G-${z.dog.firt}`;

//               newTicket.push({
//                 amount: z.amount,
//                 pay: pay.toFixed(2),
//                 dog,
//                 win: parseInt(win).toLocaleString("en-EN"),
//               });
//             } else if(z.dog.firt !=="" && z.dog.second !== "" && z.dog.third==="") {
//               pay = z.run.line[`p${z.dog.firt}${z.dog.second}`];
//               win = pay * z.amount;
//               dog = `P-${z.dog.firt}-${z.dog.second}`;
//               newTicket.push({
//                 amount: z.amount,
//                 pay: parseFloat(pay).toFixed(2),
//                 dog,
//                 win: parseInt(win).toLocaleString("en-EN"),
//               });
//               /* if (z.type.includes('ATRA')) {
//                 dog = `P-${z.dog.second}-${z.dog.firt}`
//                 newTicket.push({
//                   amount: z.amount,
//                   pay:parseFloat(pay).toFixed(2),
//                   dog,
//                   win:parseInt(win).toLocaleString('en-EN')
//                 })
//               }*/
//             }else if(z.dog.firt !=="" && z.dog.second !== "" && z.dog.third!=="") {
//               pay =z.type.includes('EXAC')? parseFloat(z.run.line[`p${z.dog.firt}${z.dog.second}`])*z.run.line.d[`p${z.dog.third}`]:parseFloat(z.run.line[`p${z.dog.firt}${z.dog.second}`])*z.run.line.d[`p${z.dog.third}`]/3;
//               win = pay * z.amount;
//               dog =z.type.includes('EXAC')? `TE-${z.dog.firt}-${z.dog.second}-${z.dog.third}`:`TC-${z.dog.firt}-${z.dog.second}-${z.dog.third}`;
//               newTicket.push({
//                 amount: z.amount,
//                 pay: parseFloat(pay).toFixed(2),
//                 dog,
//                 win: parseInt(win).toLocaleString("en-EN"),
//               });
//               /* if (z.type.includes('ATRA')) {
//                 dog = `P-${z.dog.second}-${z.dog.firt}`
//                 newTicket.push({
//                   amount: z.amount,
//                   pay:parseFloat(pay).toFixed(2),
//                   dog,
//                   win:parseInt(win).toLocaleString('en-EN')
//                 })
//               }*/
//             }else{
//               alert('Jugada Erronea, Favor Revise y Trate de Nuevo')
//             }
//           });
//           setNewTicketList(newTicket.reverse());
//         }
//       } else {
//         setBetted(0);
//         alert("Debes Seleccionar el monto apostado...");
//       }
//       setActive(true);
//     } else {
//       alert("Monto No Valido");
//     }
//   };
//   /**Seleccionar el tipo de apuesta  DIRECTO, PATRA Y PALANTE, CON TODO*/

//   /**Eliminar */
//   const Eliminar = () => {
//     if (inTeclado !=="") {
//       setShow(true);
//     } else {
//       setSelected({p11: "",p12: "",p13: "",p14: "",p15: "",p16: "",p17: "",p18: "",p21: "",p22: "",p23: "",p24: "",p25: "",p26: "",p27: "",p28: "",p31: "",p32: "",p33: "",p34: "",p35: "",p36: "",p37: "",p38: "",
//       });
//       betting = [];
//       setTotal(() => 0);
//       setFirstBest("");
//       setSecondBet("");
//       setBetted(0);
//       setNewTicketList([]);
//       newTicket = [];
//       bettingShow = [];
//     }
//   };

//   /**Eliminar Ticket */
//   const EliminarTicket = () => {
//     setShowTextArea(true);
//     fetch(`${url}/ticket/pay`, {
//       method: "post",
//       headers: headers,
//       body: JSON.stringify({ ticket: inTeclado, action: "Delete" }),
//     })
//       .then((res) => res.json())
//       .then((data) => {
//         if (typeof data != "string" && !data.msg) {
//           setWinner("");
//           setWinner(() => data.amountToPay);
//         } else if (typeof data == "string") {
//           setWinner("");
//           setWinner(() => data);
//         } else if (data.msg && data.msg.includes("agado")) {
//           print = data.ticket;
//           setWinner(data.msg);
//         }
//         if(showTicket===true){
//            setTimeout(() => setShowTicket(false), 150);
//            Clear()
//         }
//           setShow(false)
//       });
//   };
//   /**Realizar apuesta */
//   const ToPrint = () => {
//     if (localStorage.getItem("level") !== "4")
//       return (window.location.href = "/");
//     if (active === true) {
//       setActive(false);
//       fetch(`${url}/ticket`, {
//         method: "post",
//         headers: headers,
//         body: JSON.stringify(betting),
//       })
//         .then((res) => res.json())
//         .then((data) => {
//           console.log(data);
//           if (
//             (typeof data === "string" && data.includes("Error")) ||
//             (data.message && data.message.includes("allida"))
//           ) {
//             // localStorage.clear()
//             // window.location.href = '/'
//           } else {
//             setNewTicketList([]);
//             setSelected({
//               p11: "",
//               p12: "",
//               p13: "",
//               p14: "",
//               p15: "",
//               p16: "",
//               p17: "",
//               p18: "",
//               p21: "",
//               p22: "",
//               p23: "",
//               p24: "",
//               p25: "",
//               p26: "",
//               p27: "",
//               p28: "",
//               p31: "",
//               p32: "",
//               p33: "",
//               p34: "",
//               p35: "",
//               p36: "",
//               p37: "",
//               p38: "",
//             });
//             betting = [];
//             bettingShow = [];
//             setTotal(0);
//             setBetted(0);
//             contentPrint = "";
//             print = data;
//             setPrintSt(data);
//           }
//         })
//         .catch((error) => {
//           console.log("Error:", error);
//         });
//     }
//   };

//   /**Entrada por teclado */
//   const InputTeclado = (num) => {
//     setInTeclado((value) => `${value}${num}`);
//   };
//   /**Agregar a monto de apuesta */
//   const AddBetAmount = (value) => {
//     if (betted + value <= 1000) {
//       setBetted((betted) => parseFloat(betted) + parseFloat(value));
//     } else {
//       alert("Apuesta Maxima Superada");
//     }
//   };
//   /**Eliminar el ultimo caracter */
//   const Back = () => {
//     setInTeclado((value) => value.substring(0, value.length - 1));
//   };

//     /**Limpia el input del ticket */
//     const Clear = () => {
//       setInTeclado('');
//     };
//   /**Enter Para Pago */
//   const EnterPay = (action) => {
//     setShowTextArea(true);
//     setShowTicket(false);
//     fetch(`${url}/ticket/pay`, {
//       method: "post",
//       headers: headers,
//       body: JSON.stringify({ ticket: inTeclado, action }),
//     })
//       .then((res) => res.json())
//       .then((data) => {
//         if (typeof data != "string" && !data.msg) {
//           setWinner("");
//           setWinner(() => parseInt(data.amountToPay));
//         } else if (typeof data == "string") {
//           setWinner("");
//           setWinner(() => data);
//           Clear();
//         } else if (data.msg && data.msg.includes("agado")) {
//           print = data.ticket;
//           setPrintSt(() => data.ticket);
//           setWinner(data.msg);
//           Clear();
//         } else {
//           if (data.hasOwnProperty("msg") && data.msg.includes("petid")) {
//             setWinner(data.msg);
//             print = data.ticket;
//             setPrintSt(() => data.ticket);
//             setInTeclado("");
//           }
//         }

//       });
//   };

//   /** KONVAS */

//   const buttons = [
//     { number: 1, x: 50, color: `white`, border: `blue`, bg: `red` },
//     { number: 2, x: 170, color: `red`, border: `blue`, bg: `` },
//     { number: 3, x: 290, color: `black`, border: `blue`, bg: `white` },
//     { number: 4, x: 410, color: `white`, border: `blue`, bg: `blue` },
//     { number: 5, x: 530, color: `black`, border: `blue`, bg: `orange` },
//     { number: 6, x: 650, color: `yellow`, border: `blue`, bg: `green` },
//     { number: 7, x: 770, color: `yellow`, border: `blue`, bg: `black` },
//     { number: 8, x: 890, color: `white`, border: `blue`, bg: `violet` },
//   ];

//   const money = [
//     { number: 25, x: 50, color: `white`, border: `blue`, bg: `red` },
//     { number: 50, x: 50, color: `red`, border: `blue`, bg: `` },
//     { number: 100, x: 50, color: `black`, border: `blue`, bg: `white` },
//     { number: 200, x: 50, color: `white`, border: `blue`, bg: `blue` },
//   ];

//   const handleTouchMove = (e) => {
//     if (isPressing) {
//       const { x, y } = e.target.getStage().getPointerPosition();
//       buttons.forEach(({ number }) => {
//         for (let layer = 1; layer <= 3; layer++) {
//           const rectX = widthCanvas * 0.07 + (number - 1) * widthCanvas * 0.6 / 8;
//           const rectY = heightCanvas * (0.3 + (layer - 1) * 0.12);
//           const rectWidth = sizeRef * 0.1;
//           const rectHeight = sizeRef * 0.1;
//           if (x > rectX && x < rectX + rectWidth && y > rectY && y < rectY + rectHeight) {
//                 addValue(layer, number);
//           }
//         }
//       });
//     }
//   };

//   // const addValue = (layer, number) => {
//   //   setSelected(prevSelected => ({
//   //     ...prevSelected,
//   //     [`p${layer}${number}`]: prevSelected[`p${layer}${number}`] === 'betted' ? '': 'betted'
//   //   }));
//   // };

// const NavigatorComp = (path)=>{
//   window.location.href = path;
// }

//   const handleMouseMove = (e) => {
//     if (isPressing) {
//       const { x, y } = e.target.getStage().getPointerPosition();
//       buttons.forEach(({ number }) => {
//         for (let layer = 1; layer <= 3; layer++) {
//           const rectX = widthCanvas * 0.05 + (number - 1) * widthCanvas * 0.7 / 8;
//           const rectY = heightCanvas * (0.3 + (layer - 1) * 0.12);
//           const rectWidth = sizeRef * 0.1;
//           const rectHeight = sizeRef * 0.1;
//           if (x > rectX && x < rectX + rectWidth && y > rectY && y < rectY + rectHeight) {
//             addValue(layer, number);
//           }
//         }
//       });
//     }
//   };
//   const handleMouseDown = () => {
//     clearTimeout(timeoutRef.current); // Clear any existing timeout
//     setPressStartTime(new Date());
//   };

//   const handleMouseUp = () => {
//     if (pressStartTime) {
//       const pressEndTime = new Date();
//       const duration = pressEndTime - pressStartTime;
//       setPressDuration(duration);
//       setPressStartTime(null);
//     }
//   };

//   const handleTouchStart = () => {
//     // clearTimeout(timeoutRef.current); // Clear any existing timeout
//     // setIsTouching(true);
//     // setPressStartTime(new Date());
//   };

//   const handleTouchEnd = () => {
//     setLastTouch(parseInt())
//   };

//   if (!imagesLoaded) {
//     return <div className="container fs-3 text-center text-warning">Loading...</div>; // Mostrar un indicador de carga mientras se cargan las imágenes
//   }
//   /**Mostrar  */

//   if (show === "line") {
//     return <Dashboad />;
//   } else {
//     if (time) {
//       return (
//         <div className="container-fluid vh-100 vw-100">
//           <div className="yPrint print text-center">
//             <div className="p-0 m-0">
//               {print && print.winner ? (
//                 ""
//               ) : print && print.codeRun ? (
//                 <div className="p-0 m-0">
//                   <div className="col-12 justify-content-center p-0 m-0">
//                     <div className="divLogoPrint align-midle">
//                       <img className="imgLogoPrint " src={imgLogo} />
//                     </div>
//                     <div className="col-12">
//                       <Barcode
//                         value={print ? print.num : "000000"}
//                         options={{
//                           format: "code128",
//                           height: 25,
//                           displayValue: false,
//                         }}
//                         renderer="svg"
//                       />
//                     </div>
//                   </div>
//                   <h6>{print ? print.pv : ""}</h6>
//                   <h6>Ticket No.: {print ? print.num : ""}</h6>
//                   <h6>Carrera: {print ? `D8-${print.codeRun}` : ""}</h6>
//                   <h6>
//                     Fecha:{" "}
//                     {print
//                       ? `${new Date(print.date).toLocaleDateString(
//                           "es-ES"
//                         )} ${new Date(print.date)
//                           .getHours()
//                           .toString()
//                           .padStart(2, "0")}:${new Date(print.date)
//                           .getMinutes()
//                           .toString()
//                           .padStart(2, "0")}:${new Date(print.date)
//                           .getSeconds()
//                           .toString()
//                           .padStart(2, "0")}`
//                       : ""}
//                   </h6>
//                 </div>
//               ) : (
//                 <div className="p-0 m-0">
//                   <div className="col-12 justify-content-center p-0 m-0">
//                     <div className=" col-12 divLogoPrint align-midle">
//                       <img className="imgLogoPrint " src={imgLogo} />
//                     </div>
//                     <div className="col-12">
//                       <Barcode
//                         value={print ? print.num : "000000"}
//                         options={{
//                           format: "code128",
//                           height: 25,
//                           displayValue: false,
//                         }}
//                         renderer="svg"
//                       />
//                     </div>
//                   </div>
//                   <h6>{new Date().getHours()}</h6>
//                   <h6>Ticket No.: {new Date().getMinutes()}</h6>
//                   <h6>Carrera: {new Date().getSeconds()}</h6>
//                   <h6>Fecha: {new Date().toLocaleDateString("es-ES")}</h6>
//                 </div>
//               )}
//               {print && print.winner ? (
//                 <div className="text-center">
//                   <h3>Monto Ganador:</h3>
//                   <h3>
//                     {parseInt(print.winner.amountToPay).toLocaleString(
//                       "en-EN",
//                       { maximumFractionDigits: 0 }
//                     )}
//                   </h3>
//                   <div>.</div>
//                 </div>
//               ) : (
//                 <div>
//                   <table className="table table-sm col-12 p-0 m-0 lh-1">
//                     <thead>
//                       <tr>
//                         <th>Jugadas</th>
//                         <th>Apostado</th>
//                         <th>Paga</th>
//                         <th>Ganacia</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {print
//                         ? print.betted.map((z) => (
//                             <tr key={z.code} className="p-0 m-0">
//                               <td className="p-0 m-0">{z.dog}</td>
//                               <td className="p-0 m-0">
//                                 {parseInt(z.betted).toLocaleString("en-EN", {
//                                   maximumFractionDigits: 1,
//                                 })}
//                               </td>
//                               <td className="p-0 m-0">
//                                 {parseFloat(z.paying).toLocaleString("en-EN", {
//                                   maximumFractionDigits: 1,
//                                 })}
//                               </td>
//                               <td className="p-0 m-0">
//                                 {parseInt(z.win).toLocaleString("en-EN", {
//                                   maximumFractionDigits: 1,
//                                 })}
//                               </td>
//                             </tr>
//                           ))
//                         : ""}
//                     </tbody>
//                   </table>
//                   <h3>Total Apostado: {print ? print.amount : ""}</h3>
//                   <div>.</div>
//                 </div>
//               )}
//             </div>
//           </div>

//           <div className="noPrint">
//             <Modal show={show} onHide={!show} size="sm" className="" centered>
//               <Modal.Header closeButton></Modal.Header>
//               <form>
//                 <Modal.Body>
//                   <h3>Desea Anular Tickey {inTeclado}?</h3>
//                 </Modal.Body>
//                 <Modal.Footer className="col-12 row justify-content-between">
//                   <div className="col"> <button type="button" className="btn btn-warning form-control" onClick={() => setShow(false)}>Cerrar</button></div>
//                   <div className="col"> <button type="button" onClick={EliminarTicket} className="btn btn-danger form-control"> Aceptar </button></div>

//                 </Modal.Footer>
//               </form>
//             </Modal>
//             <Modal
//               show={showTicket}
//               onHide={!showTicket}
//               size=""
//               className="noPrint"
//               centered
//             >
//               <Modal.Header>
//                   <button className="btn btn-close" onClick={() => setShowTicket(false)}></button>
//               </Modal.Header>
//               <form>
//                 <Modal.Body className="col-12 text-center fs-1">Ticket: {inTeclado}</Modal.Body>
//                 <Modal.Footer>
//                   <div className="col-12 row">
//                     <div className="col">
//                       <input
//                         className="btn btn-primary form-control fs-3"
//                         type="button"
//                         onClick={() => EnterPay("pay")}
//                         onTouchStart={() => EnterPay("pay")}
//                         value={"Pagar"}
//                       />
//                     </div>

//                     <div className="col">
//                       <input
//                         type="button"
//                         className="form-control btn btn-danger fs-3"
//                         onClick={Eliminar}
//                         value="Eliminar"
//                       />
//                     </div>
//                     <div className="col">
//                       <button
//                         type="button"
//                         className="form-control btn btn-warning fs-3"
//                         onClick={() => EnterPay("repeat")}
//                       >
//                         Repetir
//                       </button>
//                     </div>
//                   </div>
//                 </Modal.Footer>
//               </form>
//             </Modal>
//             {state ? (
//               <Stage
//                 width={widthCanvas}
//                 height={heightCanvas}
//                 onTouchMove={handleTouchMove}
//               >
//                 {/**Layer Header */}
//                 <Layer>
//                   <Group
//                     width={widthCanvas}
//                     height={heightCanvas * 0.08}
//                     x={0}
//                     y={0}
//                   >
//                     {/* Fondo azul */}
//                     <Rect
//                       width={widthCanvas}
//                       height={heightCanvas * 0.08}
//                       x={0}
//                       y={0}
//                       fill="blue" // Color de fondo azul
//                     />

//                     {/* Rectángulos con nombres */}
//                     {[
//                       { name: "Venta", x: wt, to: "/bet" },
//                       { name: "Linea", x: wt, to: "/line" },
//                       { name: "Historial", x: wt },
//                       { name: "Volumen de Venta", x: wt, to: "/venta" },
//                       { name: "Jugadores", x: wt, to: "/player" },
//                       { name: "Salir", x: wt, to: "/bet" },
//                      // { name: "Loteria", x: wt, to: "/lotery-bet" },
//                     ].map(({ name, x, to }, index) => (
//                       <Link to={to}>
//                         <Group
//                           width={widthCanvas * 0.15}
//                           height={heightCanvas * 0.06}
//                           x={widthCanvas * 0.15 * index}
//                           y={0}
//                           onTouchStart={() => NavigatorComp(to)}
//                           onClick={() => NavigatorComp(to)}
//                         >
//                           <React.Fragment key={index}>
//                             {/* Rectángulo */}
//                             <Rect
//                               x={0}
//                               y={heightCanvas * 0.01}
//                               width={widthCanvas * 0.12}
//                               height={heightCanvas * 0.05}
//                               fill="red"
//                               stroke="black"
//                               strokeWidth={5}
//                               cornerRadius={20} // Bordes redondeados
//                             />
//                             {/* Texto con nombre */}
//                             <Text
//                               text={name}
//                               fontSize={sizeRef * 0.02}
//                               fill="white"
//                               x={0}
//                               y={heightCanvas * 0.01}
//                               width={widthCanvas * 0.12}
//                               height={heightCanvas * 0.05}
//                               align="center"
//                               verticalAlign="middle"
//                               padding={sizeRef * 0.01} // Ajuste del texto dentro del rectángulo
//                               stroke={'white'}
//                               strokeWidth={1.2}
//                               fontFamily="Verdana"
//                             />
//                           </React.Fragment>
//                         </Group>
//                       </Link>
//                     ))}
//                   </Group>
//                 </Layer>
//                 {/**Layer Generico */}
//                 <Layer>
//                   <Group
//                     x={0}
//                     y={heightCanvas * 0.071}
//                     width={widthCanvas}
//                     height={heightCanvas * 0.1}
//                   >
//                     <Group
//                       x={0}
//                       y={heightCanvas * 0.02}
//                       width={widthCanvas * 0.2}
//                       height={heightCanvas * 0.1}
//                     >
//                       <Text
//                         text="Carrera"
//                         x={0}
//                         y={0}
//                         width={widthCanvas * 0.2}
//                         height={heightCanvas * 0.03}
//                         fontSize={sizeRef * 0.03}
//                         fontFamily="Arial"
//                         fill="white"
//                         align="center"
//                         verticalAlign="top"
//                       />
//                       <Text
//                         text={run ? run.code : ""}
//                         x={0}
//                         y={heightCanvas * 0.03}
//                         width={widthCanvas * 0.2}
//                         height={heightCanvas * 0.1}
//                         fontSize={sizeRef * 0.04}
//                         fontFamily="Arial"
//                         fill="white"
//                         align="center"
//                         verticalAlign="top"
//                       />
//                     </Group>
//                     <Group
//                       x={widthCanvas * 0.25}
//                       y={heightCanvas * 0.02}
//                       width={widthCanvas * 0.2}
//                       height={heightCanvas * 0.1}
//                     >
//                       <Text
//                         text="Inicia"
//                         x={0}
//                         y={0}
//                         width={widthCanvas * 0.2}
//                         height={heightCanvas * 0.03}
//                         fontSize={sizeRef * 0.03}
//                         fontFamily="Arial"
//                         fill="white"
//                         align="center"
//                         verticalAlign="top"
//                       />
//                       <Text
//                         text={nextRun}
//                         x={0}
//                         y={heightCanvas * 0.03}
//                         width={widthCanvas * 0.2}
//                         height={heightCanvas * 0.1}
//                         fontSize={sizeRef * 0.04}
//                         fontFamily="Arial"
//                         fill="white"
//                         align="center"
//                         verticalAlign="top"
//                       />
//                     </Group>
//                     <Group
//                       x={widthCanvas * 0.5}
//                       y={heightCanvas * 0.02}
//                       width={heightCanvas * 0.2}
//                       height={heightCanvas * 0.1}
//                     >
//                       <Text
//                         text="Actual"
//                         x={0}
//                         y={0}
//                         width={widthCanvas * 0.2}
//                         height={heightCanvas * 0.1}
//                         fontSize={sizeRef * 0.03}
//                         fontFamily="Arial"
//                         fill="white"
//                         align="center"
//                         verticalAlign="top"
//                       />
//                       <Text
//                         text={hour}
//                         x={0}
//                         y={heightCanvas * 0.03}
//                         width={widthCanvas * 0.2}
//                         height={heightCanvas * 0.1}
//                         fontSize={sizeRef * 0.04}
//                         fontFamily="Arial"
//                         fill="white"
//                         align="center"
//                         verticalAlign="top"
//                       />
//                     </Group>
//                     <Group
//                       x={widthCanvas * 0.75}
//                       y={heightCanvas * 0.02}
//                       width={widthCanvas * 0.2}
//                       height={heightCanvas * 0.1}
//                     >
//                       <Text
//                         text="Segundos"
//                         x={0}
//                         y={0}
//                         width={widthCanvas * 0.2}
//                         height={heightCanvas * 0.1}
//                         fontSize={sizeRef * 0.03}
//                         fontFamily="Arial"
//                         fill="white"
//                         align="center"
//                         verticalAlign="top"
//                       />
//                       <Text
//                         text={time}
//                         x={0}
//                         y={heightCanvas * 0.03}
//                         width={widthCanvas * 0.2}
//                         height={heightCanvas * 0.1}
//                         fontSize={sizeRef * 0.04}
//                         fontFamily="Arial"
//                         fill="white"
//                         align="center"
//                         verticalAlign="top"
//                       />
//                     </Group>
//                   </Group>
//                   {/** Data Bet and Ticket*/}

//                   <Group
//                     x={widthCanvas * 0.1}
//                     y={heightCanvas * 0.18}
//                     width={widthCanvas * 0.6}
//                     height={heightCanvas * 0.071}
//                   >
//                     <Group
//                       x={0}
//                       y={0}
//                       width={widthCanvas * 0.1}
//                       height={heightCanvas * 0.071}
//                     >
//                       <Text
//                         x={0}
//                         y={0}
//                         text="Valor Jugada Actual"
//                         width={widthCanvas * 0.3}
//                         height={heightCanvas * 0.03}
//                         fill="white"
//                         fontSize={sizeRef * 0.03}
//                         verticalAlign="botton"
//                       />
//                       <Rect
//                         x={0}
//                         y={heightCanvas * 0.035}
//                         width={widthCanvas * 0.2}
//                         height={heightCanvas * 0.06}
//                         fill="white"
//                         cornerRadius={10}
//                       />
//                       <Text
//                         text={betted}
//                         x={0}
//                         y={heightCanvas * 0.035}
//                         fontSize={sizeRef * 0.05}
//                         width={widthCanvas * 0.2}
//                         height={heightCanvas * 0.06}
//                         fill="black"
//                         align="center"
//                         verticalAlign="middle"
//                       />
//                     </Group>
//                     <Group
//                       x={widthCanvas * 0.3}
//                       y={0}
//                       width={widthCanvas * 0.1}
//                       height={heightCanvas * 0.071}
//                     >
//                       <Text
//                         text="Valor Ticket Actual"
//                         x={0}
//                         y={0}
//                         width={widthCanvas * 0.3}
//                         height={heightCanvas * 0.03}
//                         fill="white"
//                         fontSize={sizeRef * 0.03}
//                         verticalAlign="botton"
//                       />
//                       <Rect
//                         x={0}
//                         y={heightCanvas * 0.035}
//                         width={widthCanvas * 0.2}
//                         height={heightCanvas * 0.06}
//                         fill="white"
//                         cornerRadius={10}
//                       />
//                       <Text
//                         text={total}
//                         x={0}
//                         y={heightCanvas * 0.035}
//                         fontSize={sizeRef * 0.05}
//                         width={widthCanvas * 0.2}
//                         height={heightCanvas * 0.06}
//                         fill="black"
//                         align="center"
//                         verticalAlign="middle"
//                       />
//                     </Group>
//                   </Group>
//                 </Layer>

//                 <Layer>
//                   {/* Layer BOTTONES 1 */}
//                   <Group
//                     x={0}
//                     y={heightCanvas * 0.3}
//                     width={widthCanvas * 0.7}
//                     height={heightCanvas * 0.1}
//                   >
//                     <Group
//                       x={0}
//                       y={0}
//                       width={widthCanvas * 0.05}
//                       height={heightCanvas * 0.1}
//                     >
//                       <Text
//                         text="1"
//                         fontSize={sizeRef / 10}
//                         fill="white"
//                         x={0}
//                         y={0}
//                         align="left"
//                         verticalAlign="middle"
//                         width={widthCanvas * 0.05}
//                         height={heightCanvas * 0.1}
//                       />
//                       <Text
//                         text="0"
//                         fontSize={sizeRef / 20}
//                         fill="white"
//                         x={0}
//                         y={0}
//                         align="right"
//                         verticalAlign="top"
//                         width={widthCanvas * 0.05}
//                         height={heightCanvas * 0.1}
//                       />
//                     </Group>
//                     {buttons.map(({ number, color, border, bg }, index) => (
//                       <React.Fragment key={`1${number}`}>
//                         <Group
//                           x={
//                             widthCanvas * 0.07 + (index * widthCanvas * 0.6) / 8
//                           }
//                           y={0}
//                           width={sizeRef * 0.1}
//                           height={sizeRef * 0.1}
//                         >
//                           <Rect
//                             x={0}
//                             y={0}
//                             width={sizeRef * 0.1}
//                             height={sizeRef * 0.1}
//                             fill={
//                               selected[`p1${number}`] === "betted" ? "gray" : bg
//                             }
//                             stroke={border}
//                             strokeWidth={2}
//                             cornerRadius={10}
//                             onTouchStart={() => addValue(1, number)}
//                             onClick={() => addValue(1, number)}
//                           />
//                           <Text
//                             text={number.toString()}
//                             fontSize={sizeRef / 10}
//                             fill={
//                               selected[`p1${number}`] === "betted"
//                                 ? "brown"
//                                 : color
//                             }
//                             x={0}
//                             y={0}
//                             align="center"
//                             verticalAlign="middle"
//                             width={sizeRef * 0.1}
//                             height={sizeRef * 0.1}
//                             onTouchStart={() => addValue(1, number)}
//                             onClick={() => addValue(1, number)}
//                           />
//                         </Group>
//                       </React.Fragment>
//                     ))}
//                   </Group>

//                   {/* Layer BOTTONES 2 */}
//                   <Group
//                     x={0}
//                     y={heightCanvas * 0.42}
//                     width={widthCanvas * 0.7}
//                     height={heightCanvas * 0.1}
//                   >
//                     <Group
//                       x={0}
//                       y={0}
//                       width={widthCanvas * 0.05}
//                       height={heightCanvas * 0.1}
//                     >
//                       <Text
//                         text="2"
//                         fontSize={sizeRef / 10}
//                         fill="white"
//                         x={0}
//                         y={0}
//                         align="left"
//                         verticalAlign="middle"
//                         width={widthCanvas * 0.05}
//                         height={heightCanvas * 0.1}
//                       />
//                       <Text
//                         text="0"
//                         fontSize={sizeRef / 20}
//                         fill="white"
//                         x={0}
//                         y={0}
//                         align="right"
//                         verticalAlign="top"
//                         width={widthCanvas * 0.05}
//                         height={heightCanvas * 0.1}
//                       />
//                     </Group>
//                     {buttons.map(({ number, color, border, bg }, index) => (
//                       <React.Fragment key={`2${number}`}>
//                         <Group
//                           x={
//                             widthCanvas * 0.07 + (index * widthCanvas * 0.6) / 8
//                           }
//                           y={0}
//                           width={sizeRef * 0.1}
//                           height={sizeRef * 0.1}
//                         >
//                           <Rect
//                             x={0}
//                             y={0}
//                             width={sizeRef * 0.1}
//                             height={sizeRef * 0.1}
//                             fill={
//                               selected[`p2${number}`] === "betted" ? "gray" : bg
//                             }
//                             stroke={border}
//                             strokeWidth={2}
//                             cornerRadius={10}
//                             onTouchStart={() => addValue(2, number)}
//                             onClick={() => addValue(2, number)}
//                           />
//                           <Text
//                             text={number.toString()}
//                             fontSize={sizeRef / 10}
//                             fill={
//                               selected[`p2${number}`] === "betted"
//                                 ? "brown"
//                                 : color
//                             }
//                             x={0}
//                             y={0}
//                             align="center"
//                             verticalAlign="middle"
//                             width={sizeRef * 0.1}
//                             height={sizeRef * 0.1}
//                             onTouchStart={() => addValue(2, number)}
//                             onClick={() => addValue(2, number)}
//                           />
//                         </Group>
//                       </React.Fragment>
//                     ))}
//                   </Group>

//                   {/* Layer BOTTONES 3 */}
//                   <Group
//                     x={0}
//                     y={heightCanvas * 0.54}
//                     width={widthCanvas * 0.7}
//                     height={heightCanvas * 0.1}
//                   >
//                     <Group
//                       x={0}
//                       y={0}
//                       width={widthCanvas * 0.07}
//                       height={heightCanvas * 0.1}
//                     >
//                       <Text
//                         text="3"
//                         fontSize={sizeRef / 10}
//                         fill="white"
//                         x={0}
//                         y={0}
//                         align="left"
//                         verticalAlign="middle"
//                         width={widthCanvas * 0.05}
//                         height={heightCanvas * 0.1}
//                       />
//                       <Text
//                         text="0"
//                         fontSize={sizeRef / 20}
//                         fill="white"
//                         x={0}
//                         y={0}
//                         align="right"
//                         verticalAlign="top"
//                         width={widthCanvas * 0.05}
//                         height={heightCanvas * 0.1}
//                       />
//                     </Group>
//                     {buttons.map(({ number, color, border, bg }, index) => (
//                       <React.Fragment key={`3${number}`}>
//                         <Group
//                           x={
//                             widthCanvas * 0.07 + (index * widthCanvas * 0.6) / 8
//                           }
//                           y={0}
//                           width={sizeRef * 0.1}
//                           height={sizeRef * 0.1}
//                         >
//                           <Rect
//                             x={0}
//                             y={0}
//                             width={sizeRef * 0.1}
//                             height={sizeRef * 0.1}
//                             fill={
//                               selected[`p3${number}`] === "betted" ? "gray" : bg
//                             }
//                             stroke={border}
//                             strokeWidth={2}
//                             cornerRadius={10}
//                             onClickn={() => addValue(3, number)}
//                             onTouchStart={() => addValue(3, number)}
//                           />
//                           <Text
//                             text={number.toString()}
//                             fontSize={sizeRef / 10}
//                             fill={
//                               selected[`p3${number}`] === "betted"
//                                 ? "brown"
//                                 : color
//                             }
//                             x={0}
//                             y={0}
//                             align="center"
//                             verticalAlign="middle"
//                             width={sizeRef * 0.1}
//                             height={sizeRef * 0.1}
//                             onTouchStart={() => addValue(3, number)}
//                             onClick={() => addValue(3, number)}
//                           />
//                         </Group>
//                       </React.Fragment>
//                     ))}
//                   </Group>
//                 </Layer>
//                 {/**money */}
//                 <Layer>
//                   <Group
//                     x={0}
//                     y={heightCanvas * 0.67}
//                     width={widthCanvas * 0.7}
//                     height={heightCanvas * 0.12}
//                   >
//                     {money.map(({ number, x, color, border, bg }, index) => (
//                       <React.Fragment key={number}>
//                         <Group
//                           x={widthCanvas * 0.05 + index * widthCanvas * 0.13}
//                           y={0}
//                           width={widthCanvas * 0.12}
//                           height={heightCanvas * 0.12}
//                         >
//                           <Rect
//                             x={0}
//                             y={0}
//                             width={widthCanvas * 0.12}
//                             height={heightCanvas * 0.12}
//                             fill={"green"}
//                             stroke={border}
//                             strokeWidth={2}
//                             cornerRadius={10}
//                             onClick={() => AddBetAmount(number)}
//                             onTouchStart={() => AddBetAmount(number)}
//                           />
//                           <Text
//                             text={number.toString()}
//                             fontSize={sizeRef / 12.5}
//                             fill={"white"}
//                             x={0}
//                             y={0}
//                             align="center"
//                             verticalAlign="middle"
//                             width={widthCanvas * 0.12}
//                             height={heightCanvas * 0.12}
//                             onClick={() => AddBetAmount(number)}
//                             onTouchStart={() => AddBetAmount(number)}
//                           />
//                         </Group>
//                       </React.Fragment>
//                     ))}
//                     {btnEnter && (
//                       <KonvaImage
//                         image={btnEnter}
//                         x={widthCanvas * 0.62 - sizeRef * 0.1}
//                         y={0}
//                         width={sizeRef * 0.2}
//                         height={sizeRef * 0.12}
//                         onClick={() => EnterPay("pay")}
//                         onTouchStart={() => EnterPay("pay")}
//                       />
//                     )}
//                   </Group>
//                   {/**Option  */}
//                   <Group
//                     x={widthCanvas * 0.05}
//                     y={heightCanvas - heightCanvas * 0.18}
//                     width={widthCanvas * 0.7}
//                     height={heightCanvas * 0.15}
//                   >
//                     {btnOption && btnOption.length ? (
//                       btnOption.map(({ x, text, img }, index) => (
//                         <Group
//                           key={text}
//                           x={(widthCanvas * index * 0.7) / 8}
//                           y={0}
//                           width={(widthCanvas * index * 0.7) / 8}
//                           height={heightCanvas * 0.15}
//                         >
//                           <KonvaImage
//                             image={img}
//                             x={0}
//                             y={0}
//                             width={((widthCanvas * 0.7) / 8) * 0.9}
//                             height={heightCanvas * 0.1}
//                             onTouchStart={() =>
//                               text.includes("DIREC") ||
//                               text.includes("EXAC") ||
//                               text.includes("COLOC") ||
//                               text.includes("PATRA") ||
//                               text.includes("TODO")
//                                 ? AddBetting(text)
//                                 : text.includes("PRIN")
//                                 ? ToPrint()
//                                 : text.includes("REPETIR")
//                                 ? EnterPay("repeat")
//                                 : Eliminar()
//                             }
//                             onClick={() =>
//                               text.includes("DIREC") ||
//                               text.includes("EXAC") ||
//                               text.includes("COLOC") ||
//                               text.includes("PATRA") ||
//                               text.includes("TODO")
//                                 ? AddBetting(text)
//                                 : text.includes("PRIN")
//                                 ? ToPrint()
//                                 : text.includes("REPETIR")
//                                 ? EnterPay("repeat")
//                                 : Eliminar()
//                             }
//                           />
//                         </Group>
//                       ))
//                     ) : (
//                       <Text text="" />
//                     )}
//                   </Group>
//                 </Layer>
//                 {/**Ticket */}
//                 <Layer>
//                   <Group
//                     x={widthCanvas * 0.71}
//                     y={heightCanvas * 0.2}
//                     width={widthCanvas * 0.25}
//                     height={heightCanvas * 0.6}
//                   >
//                     {/* Grupo 1: Rectángulo con esquinas redondeadas y texto */}
//                     <Group x={0} y={0}>
//                       <Rect
//                         x={widthCanvas * 0.01}
//                         y={0}
//                         width={widthCanvas * 0.25 * 0.6}
//                         height={heightCanvas / 15}
//                         fill="white"
//                         cornerRadius={10}
//                       />
//                       <Text
//                         text={inTeclado.length > 0 ? inTeclado : "Entre Ticket"}
//                         fontSize={sizeRef / 30}
//                         width={widthCanvas * 0.25 * 0.6}
//                         height={heightCanvas / 15}
//                         verticalAlign="middle"
//                         fill="black"
//                         x={0}
//                         y={0}
//                         align="center"
//                       />
//                       {btnRepetir && (
//                         <KonvaImage
//                           image={btnRepetir.img}
//                           x={(widthCanvas * 0.15) / 8 + widthCanvas * 0.3 * 0.5}
//                           y={-heightCanvas * 0.02}
//                           width={widthCanvas * 0.08}
//                           height={heightCanvas / 10}
//                           onClick={() => EnterPay("repeat")}
//                           onTouchStart={() => EnterPay("repeat")}
//                         />
//                       )}
//                     </Group>

//                     {/* Grupo 2: Matriz 4x4 */}
//                     <Group x={0} y={heightCanvas * 0.1}>
//                       <Rect
//                         x={0}
//                         y={-heightCanvas * 0.02}
//                         width={widthCanvas * 0.25}
//                         height={heightCanvas * 0.465}
//                         fill="white"
//                       />
//                       {[
//                         "1",
//                         "2",
//                         "3",
//                         "A",
//                         "4",
//                         "5",
//                         "6",
//                         "B",
//                         "7",
//                         "8",
//                         "9",
//                         "C",
//                         "CL",
//                         "0",
//                         "<--",
//                         "D",
//                       ].map((char, index) => (
//                         <Group
//                           key={index}
//                           x={
//                             ((index % 4) * widthCanvas * 0.23) / 4 +
//                             widthCanvas * 0.01
//                           }
//                           y={Math.floor(index / 4) * heightCanvas * 0.11}
//                           width={(widthCanvas * 0.23) / 4}
//                           height={heightCanvas * 0.1}
//                         >
//                           <Rect
//                             x={0}
//                             y={0}
//                             width={(widthCanvas * 0.22) / 4}
//                             height={heightCanvas * 0.1}
//                             fill="lightgrey"
//                             stroke="black"
//                             cornerRadius={5}
//                           />
//                           <Text
//                             text={char}
//                             fontSize={sizeRef / 20}
//                             width={(widthCanvas * 0.22) / 4}
//                             height={heightCanvas * 0.1}
//                             fill="black"
//                             x={0}
//                             y={0}
//                             align="center"
//                             verticalAlign="middle"
//                             onClick={() =>
//                               char.includes("-")
//                                 ? Back()
//                                 : char.includes("CL")
//                                 ? Clear()
//                                 : InputTeclado(char)
//                             }
//                             onTouchStart={() =>
//                               char.includes("-")
//                                 ? Back()
//                                 : char.includes("CL")
//                                 ? Clear()
//                                 : InputTeclado(char)
//                             }
//                           />
//                         </Group>
//                       ))}
//                     </Group>

//                     {/* Grupo 3: Tabla con encabezado y filas dinámicas */}
//                     <Group x={0} y={heightCanvas * 0.55}>
//                       <Rect
//                         x={0}
//                         y={0}
//                         width={widthCanvas * 0.25}
//                         height={heightCanvas * 0.4}
//                         fill="lightblue"
//                         cornerRadius={10}
//                       />
//                       <Group
//                         x={widthCanvas * 0.01}
//                         y={heightCanvas * 0.01}
//                         width={widthCanvas * 0.25}
//                         height={heightCanvas * 0.04}
//                       >
//                         <Text
//                           text="#"
//                           x={0}
//                           width={widthCanvas * 0.02}
//                           align="center"
//                           fontSize={sizeRef / 50}
//                         />
//                         <Text
//                           text="Jugada"
//                           x={widthCanvas * 0.021}
//                           width={widthCanvas * 0.05}
//                           align="center"
//                           fontSize={sizeRef / 50}
//                         />
//                         <Text
//                           text="Apostado"
//                           x={widthCanvas * 0.072}
//                           width={widthCanvas * 0.06}
//                           align="center"
//                           fontSize={sizeRef / 50}
//                         />
//                         <Text
//                           text="Gana"
//                           x={widthCanvas * 0.123}
//                           width={widthCanvas * 0.06}
//                           align="center"
//                           fontSize={sizeRef / 50}
//                         />
//                         <Text
//                           text="Total"
//                           x={widthCanvas * 0.172}
//                           width={widthCanvas * 0.05}
//                           align="center"
//                           fontSize={sizeRef / 50}
//                         />
//                       </Group>
//                       <Group>
//                         {showTextArea ? (
//                           <Group
//                             x={0}
//                             y={heightCanvas * 0.03}
//                             width={widthCanvas * 0.25}
//                           >
//                             <Text
//                               text={winner.toString()}
//                               fontSize={sizeRef / 30}
//                               x={0}
//                               y={heightCanvas * 0.01}
//                               width={widthCanvas * 0.25}
//                               align="center"
//                             />
//                           </Group>
//                         ) : (
//                           newTicketList.map((row, index) => (
//                             <Group
//                               key={index}
//                               x={widthCanvas * 0.001}
//                               y={(index + 1) * heightCanvas * 0.04}
//                             >
//                               <Text
//                                 text={index + 1}
//                                 x={widthCanvas * 0.01}
//                                 width={widthCanvas * 0.02}
//                                 align="center"
//                               />
//                               <Text
//                                 text={row.dog.toString()}
//                                 x={widthCanvas * 0.03}
//                                 width={widthCanvas * 0.05}
//                                 align="center"
//                               />
//                               <Text
//                                 text={row.amount.toString()}
//                                 x={widthCanvas * 0.08}
//                                 width={widthCanvas * 0.05}
//                                 align="center"
//                               />
//                               <Text
//                                 text={row.pay.toString()}
//                                 x={widthCanvas * 0.13}
//                                 width={widthCanvas * 0.05}
//                                 align="center"
//                               />
//                               <Text
//                                 text={row.win.toString()}
//                                 x={widthCanvas * 0.18}
//                                 width={widthCanvas * 0.05}
//                                 align="center"
//                               />
//                             </Group>
//                           ))
//                         )}
//                       </Group>
//                     </Group>
//                   </Group>
//                 </Layer>
//               </Stage>
//             ) : (
//               <Stage
//                 width={window.innerWidth * 0.99}
//                 height={window.innerHeight * 0.99}
//               >
//                 <Layer>
//                   <Group
//                     x={0}
//                     y={0}
//                     width={window.innerWidth * 0.99}
//                     height={window.innerHeight * 0.99}
//                   >
//                     <Text
//                       x={0}
//                       y={0}
//                       text="Este Punto Esta Temporalmente Fuera de Servicio, Favor Contactar a su Proveedor"
//                       fill="yellow"
//                       fontSize={window.innerHeight / 20}
//                       align="center"
//                       verticalAlign="middle"
//                       width={window.innerWidth * 0.99}
//                       height={window.innerHeight * 0.99}
//                     />
//                   </Group>
//                 </Layer>
//               </Stage>
//             )}
//           </div>
//         </div>
//       );
//     } else {
//       return (
//         <div className="">
//           <header>
//             <nav>
//               <div className="">
//                 <Link className="vol venta" to="/bet">
//                   VENTA
//                 </Link>
//                 <Link to="/line" className="vol lin">
//                   LINEA
//                 </Link>
//                 <Link className="vol res" to="/history">
//                   RESULTADOS
//                 </Link>
//                 <Link className="vol volvent" to="/venta">
//                   VOLUMEN DE VENTAS
//                 </Link>
//                 <Link className="vol volvent" to="/player">
//                   JUGADORES
//                 </Link>
//                 <button className="boton_menu bg-danger" onClick={Logout}>
//                   <DoorOpenFill />
//                 </button>
//               </div>
//             </nav>
//           </header>
//           <div className="suspendid">
//             <h1 className="carrAc ">Carrera Activa...</h1>
//           </div>
//         </div>
//       );
//     }
//   }
// }
// export default Bet;
